import { useUserStore } from '@/stores'
// export default defineNuxtPlugin(() => {
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('page:finish', () => {
    const route = useRoute()
    if (route.path !== '/') {
      window.scrollTo(0, 0)
    }
    if ((window as any).dataAc) {
      setTimeout(() => {
        ;(window as any).dataAc.entryPage()
      }, 0)
    }
  })

  addRouteMiddleware(
    'global-route',
    (to, from) => {
      if (from.query.utm && !to.query.utm) {
        return { ...to, query: { ...to.query, utm: from.query.utm } }
      }
      const toPath = to.path == '/' ? '/' : to.path.replace(/(.*)\/$/, '$1')
      // 不处理默认自动走下去
      if (process.client) {
        const userStore = useUserStore()
        const noAuthRouter = [
          '/',
          '/me',
          '/aistudio',
          '/user/view-records',
          '/goods/mall',
          '/login',
          '/login/',
          '/privacy',
          '/terms',
          '/appeal',
          '/cutcase',
          '/drawimgtest',
          '/about',
          '/originality',
          '/originality/detail',
          '/userinfo',
          '/plays/list',
          '/author',
          '/event',
        ]
        if (/^\/detail\/\d+/i.test(toPath)) {
          // 如果是详情页，直接过
          return ''
        }
        // if (/^\/plays\/.+/i.test(toPath)) {
        //   return ''
        // }
        if (/^\/goods\/\d+/i.test(toPath)) {
          return ''
        }
        if (/^\/creative\/.+/i.test(toPath)) {
          return ''
        }
        if (/^\/artist\/\d+/i.test(toPath)) {
          return ''
        }
        if (/^\/series\/\d+/i.test(toPath)) {
          return ''
        }

        // if (/^\/aiplays\/.+/i.test(toPath)) {
        //   // 如果是创作页，直接过
        //   return ''
        // }
        if (!userStore.userInfo && !noAuthRouter.includes(toPath)) {
          userStore.setLastPage(to.fullPath.replace(/(.*)\/$/, '$1'))
          return '/login'
        }
      }
      // console.log(userStore.lang)
      // console.log(to.fullPath, from.fullPath)
    },
    { global: true },
  )
})
