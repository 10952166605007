export default {
    app: {
        title: 'iCOOL',
    },
    components: {
        balance: {
            empty: {
                noMore: '결제할 내역이 없습니다.',
            },
            itemBox: {
                income: '세금',
                purchase: '구매',
                withdraw: '결제하기',
                buyOrder: '주문하기',
                refund: '환불하기',
                basefee: '기본 수수료',
                settlementDividendFee: '결제 수수료',
                settlementCommissionFee: '정산 수수료',
            },
            monthBox: {
                confirm: '확인',
            },
            typeBox: {
                all: '모두',
                incomeSelect: '세금',
                purchaseSelect: '구매',
                withdrawSelect: '결제하기',
                refundSelect: '환불하기',
            },
        },
        base: {
            cotdPreSale: {
                info: '*본 상품은 현재 예약판매 중인 상품으로, 주문 후 7일 이내 배송 될 예정입니다.*',
            },
            addressPopup1: {
                back: '뒤로',
            },
            commentItem: {
                expand: '\n펼치기',
                collapse: '\n접오두기',
                totalOfN: '총 {0} 개',
            },
            dialog: {
                reminders: '알림',
                confirm: '확인',
                cancel: '취소',
            },
            footer: {
                contactiCOOL: 'iCOOL 뉴스레터 구독하기',
                emailAddress: '이메일 주소',
                join: '제출하기',
                aboutiCOOL: 'iCOOL에 대하여',
                contactUs: '문의하기',
                exchangeReturnPolicy: '교환/반품 정책',
                deliveryPolicy: '배송 정책',
                userAgreement: '이용 약관',
                termsService: '서비스 이용 약관',
                privacyAgreement: '개인정보 처리방침',
                faq: '자주 묻는 질문',
                CopyrightByiCOOL: '저작권에 대한 모든 권리는 © Icool에 있습니다.',
                business1: '사업자 등록번호: 172-81-03373',
                business2: '통신판매업신고: 2024-부천오정-0187',
                emailErrTip: '유효한 이메일을 입력해주세요',
                submitSuccess: '제출 성공',
                address1: '주소: 경기 부천시 오정구 삼작로 95번길 72 3층 ',
                address2: '서울 영등포구 의사당대로 83 오투타워 20층',
                name1: '대표자명: ',
                name2: '이호천 (David Lee)',
                phone1: '전화번호: ',
                phone2: '01068571728',
                phoneInfo: '(문의사항은 이메일 부탁드립니다.)',
                email1: '이메일: ',
                email2: `customer{'@'}i.cool`,
            },
            languagePopup: {
                en_US: 'English',
                ko_KR: '한국어',
            },
            menuPopup: {
                en_US: 'English',
                ko_KR: '한국어',
                home: '홈',
                wallet: '지갑',
                isArtist: '아티스트',
                cart: '장바구니',
                order: '주문',
                shop: '스토어',
                featuredCreative: '추천 상품',
                customize: '맞춤 상품',
                like: '좋아요',
                notice: '배송 현황',
                setUp: '설정',
                phoneCase: '스마트폰 케이스',
                logout: '로그아웃',
                signUp: '회원가입',
                login: '로그인',
                notHaveAccount: '아직 계정이 없으십니까?',
                event: '이벤트',
            },
            newShareDialog: {
                shareToThirdParties: '컨텐츠를 공유할 채널을 선택하세요',
                shareSuccess: '공유하기',
            },
            noMore: {
                noData: '아직 관련 컨텐츠가 없습니다.',
                back: '뒤로',
            },
            noMoreComment: {
                noData: '아직 관련 컨텐츠가 없습니다.',
                back: '뒤로',
            },
            noMoreList: {
                noData: '아직 관련 컨텐츠가 없습니다.',
            },
            noticeBar: {
                clickToView: 'SHOP',
                useIt: '바로 사용',
            },
            popup: {
                confirm: '확인',
            },
            tel: {
                tel: '휴대폰 번호',
            },
            empty: {
                noData: '아직 관련 컨텐츠가 없습니다.',
            },
            uploadImg: {
                onlyAcceptImg: '사진만 가능합니다.',
                onlyAcceptPng: 'PNG, JPEG 파일만 가능합니다.',
            },
        },
        card: {
            listBox: {
                back: '뒤로',
                unbind: '연동 해제',
                unbundleBankCard: '카드 연동 해제',
                unbundleBankCardContent: '카드 연동을 해지하시겠습니까?',
                unbindSuccess: '연결 해지 성공',
            },
        },
        cart: {
            empty: {
                shoppingEmpty: '장바구니가 비어 있습니다. 원하는 상품을 구매하세요',
                shop: '스토어',
            },
            itemBox: {
                expired: '만료됨',
                delete: '삭제',
            },
            paymentBox: {
                placeOrder: '주문하기',
                addAddress: '주소 추가',
                orderPayment: '주문/결제',
                shoppingAddress: '배송지 주소',
                addNotesOptional: '메모 추가(선택 사항)',
                orderDetails: '주문 세부 정보',
                orderTotal: '주문 총액',
                methodPayment: '결제 방법',
                edit: '편집',
                otherPaymentMethods: '기타 결제 방법',
                enterPromoCode: '할인코드 입력',
                validate: '인증',
                availableBalance: '사용 가능한 잔액',
                deduction: '할인',
                availableBalanceTip: '사용 가능한 잔액이 부족합니다. 결제를 다시 진행해 주세요',
                redemptionSuccessful: '인증 성공',
                invalidPromoCodeInfo: '해당 할인 코드는 사용할 수 없습니다. 확인 후 다시 시도해 주세요.',
                paySuccess: '결제 성공',
            },
            paymentGoodItemBox: {
                expired: '만료됨',
            },
        },
        collects: {
            creativeItemBox: {
                expired: '만료됨',
                creativeExpired: '크리에이티브 콘텐츠가 만료되었습니다.',
                likeSuccess: '추가에 성공 하였습니다. ',
                likeCancel: '취소 성공',
            },
            delBox: {
                delReminders: '알림 삭제',
                delRemindersContent: '이 {0} 제품을 취소하시겠습니까?',
                confirm: '확인',
                cancel: '취소',
            },
            empty: {
                emptyLike: '현재 좋아하는 항목이 없습니다',
                shop: '스토어',
                creative: '크리에이티브',
            },
            itemBox: {
                expired: '만료됨',
                goodExpired: '컨텐츠가 만료되었습니다.',
                likeSuccess: '추가에 성공 하였습니다. ',
                likeCancel: '취소 성공',
            },
        },
        comments: {
            commentBox: {
                reply: '회신',
                auth: '인증',
                openReply: '{0} 답장 보기',
                likeRet: '좋아요',
            },
            commentDialog: {
                reply: '회신',
                send: '발송',
            },
            commentPopup: {
                comment: '댓글...',
                textAndComments: '텍스트 및 댓글',
            },
            creativeList: {
                loading: '이미지 로드중',
                noMore: '더 이상 표시할 수 없습니다.',
                comment: '답장',
            },
            evaluatedItemBox: {
                pending: '검토중',
                rejected: '리뷰 내용이 현재 검토 중입니다.',
            },
            itemBox: {
                evaluate: '리뷰',
            },
            ruleBox: {
                rule1: '평가 준칙',
                evaluationRules: '평가 규칙',
                evaluationRulesT1: '1. 상세 주문 내역',
                evaluationRulesC1: '1. 주문 완료 후 60일 이내, 구매한 제품에 대한 리뷰를 작성할 수 있습니다.',
                evaluationRulesT2: '2. 주문 평가 지침.',
                evaluationRulesC2_1: `1. 평가의 객관성 보장을 위해 평가 내용이 게재된 후에는 수정 또는 삭제가 불가 합니다.`,
                evaluationRulesC2_2: `2. 동일한 상품을 여러 개 구입할 경우, 하나의 리뷰만 작성 할 수 있습니다.`,
                evaluationRulesC2_3: `3. 리뷰 작성 중 별도 링크 삽입은 불가능 합니다. 링크게 기제된 리뷰는 제출 되지 않습니다.`,
                evaluationRulesC2_4: `4. 차단된 사용자는 차단 기간 동안 리뷰를 작성할 수 없으며, 고객센터로 문의하여 상담하실 수 있습니다.`,
                evaluationRulesC2_5: `5. 작성이 완료된 리뷰는 수정 및 삭제가 불가능합니다. 이에, 리뷰 작성시 본인이나 타인의 민감한 개인정보가 노출되지 않도록 주의해 주시기 바랍니다.`,
                evaluationRulesC2_6: `6. 개인 정보 보호를 위해 리뷰 내역을 숨기려면, 주문 목록으로 이동하여 해당 주문을 숨김 처리 해야만 가능합니다. 숨김 처리된 리뷰는 추후 복원되지 않습니다.`,
                evaluationRulesC2_7: `7. "반품 및 교환 고객 리뷰 작성 (제품 반품 전 리뷰를 작성하지 않은 경우) : 제품 반품 신청 후 리뷰 작성은 불가능 하며, 반품이 완료되기 전(제품 반품 신청 전 작성된 리뷰에 한해) 리뷰가 완성된 경우에는 제품 반품 완료 후 해당 리뷰가 정상적으로 표시됩니다. 반품 및 교환 주문 상품 평가 : (제품 교환 전 리뷰를 작성하지 않은 경우) 교환 전 상품 리뷰는 제품 교환 완료 후 작성할 수 있습니다. : 주문 상품은 상품 교환 전, 후 리뷰 작성이 모두 가능합니다. 다만, 제품 교환 후 신규 리뷰를 작성하실 경우 교환 전 작성된 리뷰는 노출되지 않으며 교환 후 작성하신 리뷰만 노출 됩니다. "`,
                evaluationRulesC2_8: `8. 홈페이지 또는 APP 상에 게시된 상품 리뷰와 댓글은 SNS 채널, 포털 사이트 등에 전체 또는 요약 형태로 노출 될 수 있습니다. `,
                evaluationRulesC2_9: `9. 개인 정보 사용 : 사용자 개인 정보 보호를 위해 제품 세부 정보 페이지에 게시된 리뷰는 익명으로 표시 됩니다. `,
            },
        },
        cutcase: {
            orderBox: {
                orderDetails: '주문 세부 정보',
                piece: '수량',
                piece1: '수량',
                placeOrder: '주문하기',
            },
        },
        goods: {
            addNotesBox: {
                back: '뒤로',
                addNote: '메모 추가',
                addNotesOptional: '메모 추가(선택 사항)',
                save: '저장',
            },
            addressBox: {
                back: '뒤로',
                shoppingAddress: '배송지 주소',
                default: '위약',
                emptyAddress: '아직 주소 정보가 없습니다.',
                addAddress: '주소 추가',
            },
            addressEditBox: {
                back: '뒤로',
                editAddress: '주소 편집',
                addAddress: '주소 추가',
                addressFirst: '주소를 입력하세요.',
                delete: '삭제',
                receiver: '이름',
                name: '이름',
                tel: '전화번호',
                area: '주소',
                addArea: '기본 주소지',
                address: '상세 주소',
                enterAddress: '상세 주소 입력',
                defaultAddress: '기본 배송지로 설정',
                save: '저장',
                addressDelTip: '주소를 삭제하시겠습니까?',
                changeSuccess: '수정 성공',
                addSuccess: '추가 성공',
                delSuccess: '삭제 완료',
            },
            addShopCard: {
                merchandiseSelect: '상품 선택',
                addToCart: '장바구니에 추가하기',
                total: '합계:',
                confirm: '확인',
                pleaseChoose: '선택해주세요 {0}',
                backplane: '커버',
                backplaneInfo: '커버 (*아무거나 선택하세요)',
            },
            artistOthreProducts: {
                otherList: '아티스트의 다른 상품',
                seeAll: 'all',
            },
            buyBox: {
                addAddress: '주소 추가',
                orderPayment: '주문/결제',
                shoppingAddress: '배송지 주소',
                addNotesOptional: '메모 추가(선택 사항)',
                orderDetails: '주문 세부 정보',
                edit: '편집',
                piece: '수량',
                piece1: '수량',
                expired: '만료됨',
                priceBreakdown: '가격명세',
                methodPayment: '결제 방법',
                validate: '인증',
                enterPromoCode: '할인코드 입력',
                availableBalance: '사용 가능한 잔액',
                deduction: '할인',
                availableBalanceTip: '사용 가능한 잔액이 부족합니다. 결제를 다시 진행해 주세요',
                otherPaymentMethods: '기타 결제 방법',
                placeOrder: '주문하기',
                redemptionSuccessful: '인증 성공',
                invalidPromoCodeInfo: '해당 할인 코드는 사용할 수 없습니다. 확인 후 다시 시도해 주세요.',
                paySuccess: '결제 성공',
            },
            buyChooseSku: {
                merchandiseSelect: '상품 선택',
                total: '합계:',
                confirm: '확인',
                pleaseChoose: '선택해주세요 {0}',
            },
            commentBox: {
                auth: '인증',
                reply: '회신',
            },
            creativeGoodsItem: {
                likeSuccess: '추가에 성공 하였습니다. ',
                likeCancel: '취소 성공',
            },
            filters: {
                confirm: '확인',
                showMore: '더 보기',
                showLess: '닫기',
                series: '시리즈',
                artist: '아티스트',
            },
            goodIdsPopup: {
                popupTitle: '관련 상품',
            },
            goodsSortPopup: {
                default: '전체',
                saleDESC: '판매량 높은 순',
                priceDESC: '가격 높은 순',
                priceASC: '가격 낮은 순',
            },
            item: {
                likeSuccess: '추가에 성공 하였습니다. ',
                likeCancel: '취소 성공',
            },
            priceDetailsBox: {
                totalPrice: '상품총액',
                freight: '배송비',
                freeTime1: '제한시간',
                freeTime2: '무료체험',
                discount: '할인',
                total: '합계',
            },
            searchPopup: {
                loading: '이미지 로드중',
                noMore: '더 이상 표시할 수 없습니다.',
                searchGoods: '상품 검색',
                empty: '이 제품에 대한 검색 내역이 없습니다. ',
            },
        },
        home: {
            authsBox: {
                fans: '팔로워',
                follow: '팔로우',
                unFollow: '팔로우 취소',
                followSuccess: '필로우 성공',
            },
            creativeBox: {
                featuredCreative1: '크리에이티브',
                seeMore: '더 보기',
            },
            creativeCenterList: {
                loading: '이미지 로드중',
                loosing: '새로고침 가능',
                noMore: '더 이상 표시할 수 없습니다.',
            },
            creativeGoodsPopupList: {
                popupTitle: '관련 상품',
                likeSuccess: '추가에 성공 하였습니다. ',
                likeCancel: '취소 성공',
            },
            creativeHomeItem: {
                likeSuccess: '추가에 성공 하였습니다. ',
                likeCancel: '취소 성공',
            },
            creativeItem: {
                buyIt: '구매',
                more: '더 보기',
                collapse: '접기',
                likeSuccess: '추가에 성공 하였습니다. ',
                likeCancel: '취소 성공',
            },
            customBox: {
                customize: '맞춤 상품',
                seeMore: '더 보기',
                useIt: '사용',
            },
            hotBox: {
                seeMore: '더 보기',
            },
            hotItem: {
                likeSuccess: '추가에 성공 하였습니다. ',
                likeCancel: '취소 성공',
            },
            seriesBox: {
                viewThisSeries: '구매하기',
            },
        },
        logistic: {
            itemBox: {
                orderRefundTitle: '주문 환불 및 교환 정보',
                orderNormalTitle: '주문 배송 정보',
                packageInfo: '패키지 정보',
                packageDynamics: '패키지 동향',
                refundInfoDynamics: '패키지 현황',
                refundTitleSuccess: '귀하의  환불 주문건이  정상적으로 처리되었습니다. ',
                refundTitleFaild: '귀하의 환불 주문건이  취소되었습니다.',
                refundInfoSuccess: '환불 처리되었습니다.',
                refundInfoFaild: '환불 신청에 실패하였습니다. 다시 시도해 주십시오',
                bvy: '구매',
                replace: '교환  ',
                view: '보기',
                logistics_level_msg_title1: '{0} 상품이 출고되었습니다',
                logistics_level_msg_title3: '{0} 상품이 배송 중입니다',
                logistics_level_msg_title6: '{0} 상품 배송이 완료 되었습니다',
            },
        },
        message: {
            comment: {
                loading: '이미지 로드중',
                loosing: '새로고침 가능',
                noMore: '더 이상 표시할 수 없습니다.',
                commentTitle: '리뷰 리스트',
                getNewMsg: '새 메시지 수신',
                placePullRefresh: '새로고침 하려면 스크롤을 아래로 내려주세요',
                auth: '인증',
            },
            focus: {
                loading: '이미지 로드중',
                loosing: '새로고침 가능',
                noMore: '더 이상 표시할 수 없습니다.',
                getNewMsg: '새 메시지 수신',
                placePullRefresh: '새로고침 하려면 스크롤을 아래로 내려주세요',
                followTitle: '팔로우 리스트',
                followMe: '당신을 팔로우했어요',
                follow: '팔로우',
                unFollow: '팔로우 취소',
                followSuccess: '필로우 성공',
            },
            friend: {
                loading: '이미지 로드중',
                loosing: '새로고침 가능',
                noMore: '더 이상 표시할 수 없습니다.',
                getNewMsg: '새 메시지 수신',
                placePullRefresh: '새로고침 하려면 스크롤을 아래로 내려주세요',
                friendMessageList: '친구 소식 목록',
                officialNotice: '공지',
            },
            friendMsgPopup: {
                loading: '이미지 로드중',
                loosing: '새로고침 가능',
                noMore: '더 이상 표시할 수 없습니다.',
                friendMessage1: '친구 메시지',
                fans: '팔로워',
                follow: '팔로우',
                followSuccess: '필로우 성공',
            },
            like: {
                loading: '이미지 로드중',
                loosing: '새로고침 가능',
                noMore: '더 이상 표시할 수 없습니다.',
                getNewMsg: '새 메시지 수신',
                placePullRefresh: '새로고침 하려면 스크롤을 아래로 내려주세요',
                starMeTitle: '즐겨찾기 및 즐겨찾기 목록',
            },
            sysMsgPopup: {
                loading: '이미지 로드중',
                loosing: '새로고침 가능',
                noMore: '더 이상 표시할 수 없습니다.',
                officialNotice: '공지',
                title: 'iCOOL',
            },
        },
        order: {
            applyAfterSales: {
                back: '뒤로',
                submitAfterSales: 'CS 작성',
                afterSalesExchange: '교환',
                afterSalesReasons: 'CS 사유',
                justRefund: '환불 요청',
                returnRefund: '반품 환불',
                placeInput: '내용을 입력하세요.',
                placeInput1: '내용을 입력하세요.',
                submit: '최근 보기',
                submitSuccess: '제출 성공',
                returnPolicyTips: '이 플랫폼은 맞춤형 제품이므로 비상품 문제에 대한 환불이 지원되지 않습니다. 클릭하여 확인하세요.',
                returnPolicyBtn: '반품 정책',
                exchangeReturnPolicy: '교환/반품 정책',
                timeoutNotShipped: '시간 초과 배송되지 않음',
                timeoutNotReceived: '시간 초과 수령되지 않음',
                receivedGoodsDoNotMatch: '받은 상품이 설명과 일치하지 않거나, 손상되었거나 결함이 있는 상품',
                selectAfterSaleItems: '애프터 서비스 항목을 선택하세요.',
            },
            confirmOrder: {
                piece: '수량',
                toBePaid: '주문 금액: ',
                actuallyPaid: '결제 금액: ',
                payable: '최종 결제 금액: ',
                confirmReceipt: '구매 확정',
                confirmReceiptInfo: '제품을 받으셨습니까?',
                confirmReceiptSuccess: '제품을 수령했습니다.',
                confirm: '확인',
                cancel: '취소',
            },
            delOrder: {
                piece: '수량',
                toBePaid: '주문 금액: ',
                actuallyPaid: '결제 금액: ',
                payable: '최종 결제 금액: ',
                deleteOrder: '주문 삭제',
                deleteReminderTitle: '알림 삭제',
                deleteReminderContent: '이 주문을 삭제 하시겠습니까?',
                cancel: '취소',
                delSuccess: '삭제 완료',
            },
            detailDelOrder: {
                piece: '수량',
                toBePaid: '주문 금액: ',
                actuallyPaid: '결제 금액: ',
                payable: '최종 결제 금액: ',
                deleteOrder: '주문 삭제',
                deleteReminderTitle: '알림 삭제',
                deleteReminderContent: '이 주문을 삭제 하시겠습니까?',
                cancel: '취소',
                delSuccess: '삭제 완료',
            },
            itemBox: {
                orderStatus: {
                    1: '결제 대기',
                    2: '배송준비',
                    3: '배송중',
                    4: '리뷰 작성',
                    5: '구매확정',
                    6: '취소됨',
                    7: '교환/반품',
                    8: '배송준비중',
                },
                afterSaleStatus: {
                    '-1': '사용자가 취소합니다',
                    1: '검토 대기 중',
                    2: '배송준비중',
                    3: '배송중',
                    4: '배송완료',
                    5: '감사 거부',
                    6: '환불 대기중',
                    7: '환불 중',
                },
                piece: '수량',
                total: '합계:',
                viewLogistics: '택배 조회',
                confirmReceipt: '구매 확정',
                applyAfterSales: 'CS 신청중',
                afterSalesProgress: 'CS 진행 내역',
                deleteOrder: '주문 삭제',
                payment: '결제',
                afterSales: 'CS',
                view: '보기',
            },
            lookAfterSales: {
                back: '뒤로',
                afterSaleEventStatus: {
                    Refunding: '환불 중',
                    RefundFail: '환불 실패',
                    ReplaceGoodsSucc: '교환 완료',
                    ReplaceGoodsFail: '교환 실패',
                    ReplaceGoodsing: '교환 중',
                    RequestAfterSale: '심사 제출',
                    WaitReview: '심사 대기 중',
                    ReviewResolve: '심사 통과',
                    ReviewReject: '심사 거절',
                    WaitRefund: '환불대기 중',
                    RefundSucc: '환불완료',
                    WaitShip: '배송대기 중',
                    WaitReceiveGoods: '배송 중',
                    Completed: '배송 완료',
                    UserCancel: '사용자 취소',
                },
                refundSuccTxt: 'iCOOL 플랫폼에서 환불이 처리되었습니다. 7영업일 이내에 입금될 것으로 예상됩니다.',
            },
            orderItemInfo: {
                piece: '수량',
            },
        },
        originality: {
            card: {
                follow: '팔로우',
                unFollow: '팔로우 취소',
                dontFollow: '더 이상 관심 없음?',
                followSuccess: '필로우 성공',
            },
            detailGoodItem: {
                likeSuccess: '추가에 성공 하였습니다. ',
                likeCancel: '취소 성공',
            },
            goods: {
                mall: '추천',
            },
            itineraryList: {
                itinerary: '일정',
                dateInfo: '일자',
            },
            story: {
                storyTitle: '크리에이티브 스토리',
            },
        },
        plays: {
            c: {
                material: {
                    background: {
                        back: '뒤로',
                        next: '다음',
                    },
                    collectionList: {
                        loading: '이미지 로드중',
                        loosing: '새로고침 가능',
                        noMore: '더 이상 표시할 수 없습니다.',
                        emptyInfo: '현재 관련 자료가 없습니다.',
                    },
                    delMaterialBox: {
                        delMaterialTitle: '삭제된 자료 목록',
                        delMaterialContent: '이 사진을 삭제 하시겠습니까?',
                        confirm: '확인',
                        cancel: '취소',
                    },
                    index: {
                        back: '뒤로',
                        myCollection: '내 컬렉션',
                        myUploads: '나의 업로드',
                        officialSelection: '선택',
                    },
                    offerListItem: {
                        loading: '이미지 로드중',
                        loosing: '새로고침 가능',
                        noMore: '더 이상 표시할 수 없습니다.',
                        emptyInfo: '현재 관련 자료가 없습니다.',
                    },
                    uploadList: {
                        loading: '이미지 로드중',
                        loosing: '새로고침 가능',
                        noMore: '더 이상 표시할 수 없습니다.',
                        emptyInfo: '현재 관련 자료가 없습니다.',
                        uploadImage: '이미지 업로드',
                    },
                },
                loadImg: {
                    generationStep1: '이미지 / 텍스트 분석 중',
                    generationStep2: 'AI 파라미터 매칭 중',
                    generationStep3: '색상 채우는 중',
                },
                useAiStyle: {
                    back: '뒤로',
                    useIt: '사용',
                },
                usePattern: {
                    copies: '복사',
                    type: '유형',
                },
                useTxtInput: {
                    firstGenerate: '생성',
                    descrihatPlaceHolder: '스마트폰 케이스에 노출하고 싶은 텍스트를 작성해 주세요',
                },
                cake: {
                    back: '뒤로',
                    confirm: '확인',
                },
                lens: {
                    back: '뒤로',
                    confirm: '확인',
                },
            },
            config: {
                componentsConfig: {
                    play31Placeholder: '장미, 튤립, 데이지, 해바라기, 로투스, 오키드',
                    play31ConfirmText: '다음',
                    play34Placeholder: '아이스크림, 선글라스, 모자, 슬리퍼, 주스',
                    play34ConfirmText: '다음',
                    play32Placeholder: '망고, 캔디, 바닐라, 딸기',
                    play32ConfirmText: '다음',
                    play43Placeholder: '무언가를 입력하세요...(단어 3개만)',
                },
            },
            fabric: {
                base: {
                    text: {
                        control: {
                            font: '글꼴',
                            color: '색상',
                            keyboard: '키보드',
                        },
                        editTextModal: {
                            clickToEdit: '편집하려면 클릭하세요',
                            play7TxtInfo: '문자만 허용됨',
                        },
                    },
                    layerControl: {
                        forward: '앞',
                        backward: '뒤',
                        horizontal: '수평',
                        vertical: '세로',
                    },
                },
                textFontPopup: {
                    fontTab: '글꼴',
                    strokeTab: '스트로크',
                    none: '없음',
                    black: '검정',
                    white: '흰색',
                    green: '녹색',
                    purple: '보라색',
                    alpha: '알파',
                    width: '두께',
                },
            },
            cutPhoneCase: {
                back: '뒤로',
                phoneCase: '케이스',
                uploadImage: '이미지 업로드',
                uploadImageInfo: '* 사진을 업로드해 주세요.',
                uploadImageInfo1: '* 선명한 사진을 업로드해 주세요.',
                uploadImageInfoAccept: '* Jpeg,png사진으로 업로드 부탁드려요.',
                portraitOnly: '초상화만',
                uploadTxt: '업로드하려면 클릭하세요',
                next: '다음',
                font: '폰트',
                noImgTipTitle: '안내',
                noImgTipInfo: '품질을 보장하려면 이미지를 작업 영역에 배치하십시오.',
                noImgTipConfirm: '알겠어요',
                playsError: '아직 지원되지 않는 서비스입니다.',
                play22Placeholder: '내용 입력...({0}단어 이하)',
                play42Info: '* 2장의 사진이 앞,뒤 겹쳐진 이미지로 적용됩니다.',
                play42Info1: '(예시 이미지 참고)',
                play48Info: '* 두 장의 이미지를 업로드해주세요. 상업용 이미지는 허용되지 않습니다.',
                play48Info1: '이미지는 1MB 이하로 제한됩니다.',
                play48ImgTip1: '선호하는 스타일',
                play48ImgTip2: '변화시키고 싶은 스타일',
            },
            drawPhoneCase: {
                back: '뒤로',
                generationFailed: '생성 실패',
                case: '핸드폰 케이스',
                upload: '업로드',
                style: '스타일',
                library: '재료 모음',
                font: '폰트',
                cutout: '자르기',
                useText: '텍스트 사용하기',
                pattern: '패턴',
                stencil: '원판(스텐실)',
                graffiti: '그래피티',
                background: '배경',
                keyboard: '키보드',
                save: '저장',
                noImgTipTitle: '안내',
                noImgTipConfirm: '알겠어요',
                quitTipInfo: '정말로 닫으시겠어요? 끄면 현재 작업이 저장되지 않습니다.',
                cutTipTitle: '자르기',
                cutTipInfo: '자르기 효과를 유지하려면 가장자리가 선명하고 본체가 있는 이미지를 선택하세요.',
                noTip: '더 이상 메시지가 표시되지 않습니다.',
                cake: '케이크',
                again: '다시 한 번',
                lens: '어안렌즈',
            },
            prePhoneCase: {
                back: '뒤로',
                generationFailed: '생성 실패',
                case: '핸드폰 케이스',
                upload: '업로드',
                style: '스타일',
                library: '재료 모음',
                font: '폰트',
                cutout: '자르기',
                useText: '텍스트 사용하기',
                pattern: '패턴',
                stencil: '원판(스텐실)',
                graffiti: '그래피티',
                background: '배경',
                keyboard: '키보드',
                save: '저장',
                noImgTipTitle: '안내',
                noImgTipConfirm: '알겠어요',
                quitTipInfo: '정말로 닫으시겠어요? 끄면 현재 작업이 저장되지 않습니다.',
                cutTipTitle: '자르기',
                cutTipInfo: '자르기 효과를 유지하려면 가장자리가 선명하고 본체가 있는 이미지를 선택하세요.',
                noTip: '더 이상 메시지가 표시되지 않습니다.',
                cake: '케이크',
                again: '다시 한 번',
                lens: '어안렌즈',
            },
            selectPhone: {
                back: '뒤로',
                phoneCase: '핸드폰 케이스',
                filter: '필터',
                next: '다음',
                confirm: '확인',
            },
            lens: {
                back: '뒤로',
                next: '다음',
            },
        },
        user: {
            historyNoMorePage: {
                loading: '이미지 로드중',
                noMore: '더 이상 표시할 수 없습니다.',
                recommendForYou: '추천',
            },
        },
        withdraw: {
            noAuthBox: {
                withdreawalTips: '출금 안내',
                withdreawalTipsContent: '계정 보안을 위해 스마트폰 번호 또는 이메일 주소를 연동하고 확인 후 출금하시기 바랍니다',
                withdreawalTipsConfirm: '연동하기',
                cancel: '취소',
            },
            verificationBox: {
                back: '뒤로',
                verificationIdentity: '본인 확인',
                verificationCode: '인증번호',
                getVerificationCode: '인증번호 확인',
                emailAuthentication: '이메일 인증',
                telAuthentication: '번호 인증',
                confirm: '확인',
            },
        },
    },
    pages: {
        author: {
            expand: '\n펼치기',
            collapse: '\n접오두기',
            fans: '팬',
            followers: '관심사',
            follow: '팔로우',
            unFollow: '팔로우 취소',
            product: '상품',
            loading: '이미지 로드중',
            noMore: '더 이상 표시할 수 없습니다.',
            empty: '관련 데이터 없음',
            dontFollow: '더 이상 관심 없음?',
            followSuccess: '필로우 성공',
            artwork: '작품',
        },
        cart: {
            cart: '장바구니',
            addAddress: '주소',
            done: '완료',
            manage: '관리',
            loading: '이미지 로드중',
            noMore: '더 이상 표시할 수 없습니다.',
            recommendForYou: '추천',
            all: '모두',
            selected: '선택 {0}',
            delete: '삭제',
            placeOrder: '주문하기',
            delSku: '이 {0} 제품을 삭제하시겠습니까?',
            noSelect: '아직 선택된 제품이 없습니다.',
            delSuccess: '삭제 완료',
            total: '합계:',
        },
        creative: {
            video: {
                empty: '이 광고 소재 콘텐츠는 더 이상 유효하지 않습니다.',
                more: '더 보기',
                collapse: '접기',
                share: '공유하기',
                buyIt: '구매',
                likeCancel: '취소 성공',
                likeSuccess: '추가에 성공 하였습니다.',
            },
            article: {
                empty: '이 광고 소재 콘텐츠는 더 이상 유효하지 않습니다.',
                share: '공유하기',
                likeCancel: '취소 성공',
                likeSuccess: '추가에 성공 하였습니다. ',
                buyIt: '구매',
            },
        },
        goods: {
            detail: {
                addedToCart: '장바구니에 성공적으로 추가되었습니다',
                details: '세부 정보',
                by: 'By',
                review: '리뷰',
                loading: '이미지 로드중',
                comment: '리뷰',
                default: '전체',
                newest: '최신',
                seeAllComments: '모든 리뷰 보기',
                likeCancel: '취소 성공',
                likeSuccess: '추가에 성공 하였습니다. ',
                cart: '장바구니',
                purchase: '구매하기',
                tagv1: '첫 주문 30일 무료 반품 교환',
                tagv2: '한국에서 직접 인쇄',
                tagv3: '무료 배송',
                tagv4: '비예약 상품은 평일 주문 시 2일 이내, 주말 주문 시 3-4일 이내에 배송됩니다',
            },
            allcomment: {
                comment: '리뷰',
                ealuationYet: '아직 작성된 리뷰가 없습니다.',
                default: '전체',
                newest: '최신',
                loading: '이미지 로드중',
                noMore: '더 이상 표시할 수 없습니다.',
            },
            mall: {
                searchGoods: '상품 검색',
                phoneCase: '스마트폰 케이스',
                filters: '필터',
                loading: '이미지 로드중',
                loosing: '새로고침 가능',
                noMore: '더 이상 표시할 수 없습니다.',
                default: '전체',
                saleDESC: '판매량 높은 순',
                saleASC: '판매량 낮은 순',
                priceDESC: '가격 높은 순',
                priceASC: '가격 낮은 순',
            },
            fail: {
                payFail: '결제 실패',
                backHome: '홈',
                viewOrder: '주문 보기',
            },
            result: {
                paySuccess: '결제 성공',
                paySuccessInfo1: '제품을 제작중에 있습니다. 빠른 시일안에 배송 될 예정입니다. ',
                backToShop: '쇼핑몰로 돌아가기',
                viewOrder: '주문 보기',
                payFail: '결제 실패',
                insTips1: '제품 구매 후, iCOOL 인스타그램(',
                insTips2: 'icool.kr',
                insTips3: ') 프로필 링크 내 폼 작성을 완료해야 추첨 대상에 포함됩니다.',
                insBtn: 'Go Now',
            },
        },
        originality: {
            loading: '이미지 로드중',
            noMore: '더 이상 표시할 수 없습니다.',
            empty: '관련 데이터 없음',
            all: '모두',
            detail: {
                artist: '아티스트',
                designer: '크리에이터',
                follow: '팔로우',
                unFollow: '팔로우 취소',
                coCreativeDesigner: '아티스트 공동창작',
                loading: '이미지 로드중',
                noMore: '더 이상 표시할 수 없습니다.',
                dontFollow: '더 이상 관심 없음?',
                followSuccess: '필로우 성공',
            },
        },
        plays: {
            list: {
                customizeCenter: '맞춤 제작 센터',
                customizeTemp: '제작 방식',
                loading: '이미지 로드중',
                loosing: '새로고침 가능',
                noMore: '더 이상 표시할 수 없습니다.',
                useIt: '사용',
                all: '전체',
            },
            mbti: {
                //未上线
                phoneCase: '핸드폰 케이스',
                next: '다음',
                title: '*MBTI 역할을 선택해 주세요.',
            },
            cake: {
                //未上线
                phoneCase: '핸드폰 케이스',
                next: '다음',
                title: '*좋아하는 케이크 맛을 선택하세요.',
            },
        },
        series: {
            artist: '아티스트',
            designer: '크리에이터',
            follow: '팔로우',
            unFollow: '팔로우 취소',
            coCreativeDesigner: '아티스트 공동창작',
            series: '시리즈',
            loading: '이미지 로드중',
            noMore: '더 이상 표시할 수 없습니다.',
            confirm: '확인',
            cancel: '취소 성공',
            followSuccess: '필로우 성공',
            dontFollow: '더 이상 관심 없음?',
        },
        user: {
            browser: {
                browserRecord: '최근 본 상품',
                manage: '편집',
                done: '완료',
                merchandise: '상품 {0}',
                creative: '창의적인 {0}',
                noHistory: '기록 없음',
                shopNow: '찾아보기',
                loading: '이미지 로드중',
                noMore: '더 이상 표시할 수 없습니다.',
                expired: '만료됨',
                all: '모두 선택',
                delete: '삭제',
                selected: '선택 {0}',
                today: '오늘',
                noSelectGoods: '선택된 상품이 없습니다.',
                noSelectCreative: '선택된 테마가 없습니다.',
                delSuccess: '삭제 완료',
            },
            commentSuccess: {
                loading: '이미지 로드중',
                noMoreProductsEvaluated: '리뷰 대기 제품이 없습니다.',
                evaluationCompleted: '리뷰 완료',
                evaluationCompleted1: '리뷰 작성 완료 , 감사합니다.',
                view: '리뷰 보기',
                keepTheReviewsComing: '계속해서 리뷰하기',
                evaluate: '평가',
            },
            comment: {
                productScore: '상품 평가',
                wordsEntered1: '이미 작성됨',
                wordsEntered2: '단어',
                placeholderTxt: '평가 내용 작성  ',
                addPhoto: '그림 추가',
                surolusNum: '{0} 장 추가 가능',
                remarkTxt: '평가 내용은 상품 평가 페이지에서 익명으로 노출 됩니다.',
                publish: '게시',
                star1: '매우 나쁨',
                star2: '나쁨',
                star3: '보통',
                star4: '좋다',
                star5: '아주 좋다',
                placeInputTxt: '평가 내용을 작성해 주세요',
                evaluationSuccess: '리뷰 작성을 완료 하였습니다.',
            },
            follow: {
                addFollow: '친구 추가',
                inputPlaceholder: '*는 필수 항목입니다. ',
                recommended: '추천',
            },
            goodExpired: {
                productExpired: '해당 제품 품절',
                goToMall: '제품 선택',
            },
            balance: {
                walletBalance: '지갑 잔액',
                totalBalance: '총 자산',
                bankCard: '카드 ({0})',
                withdraw: '출금',
                bill: '영수증',
                noMore: '더 이상 표시할 수 없습니다.',
                all: '모두',
            },
            bill: {
                details: '세부정보',
                withdrawTime: '출금 시간',
                arrivalTime: '도착 시간',
                card: '카드 번호',
                withdrawType: '출금 유형',
                withdrawID: '출금 ID',
                transactionType: '거래 유형',
                orderAmount: '주문 금액',
                coolProductPricing: '총 가격',
                orderID: '주문 ID',
            },
            card: {
                add: {
                    addBankCard: '카드 추가하기',
                    submit: '제출 ',
                },
                list: {
                    name: '카드 성명',
                    unbind: '연동 해제',
                    noBankCard: '현재 등록된 카드가 없습니다.',
                    addBankCard: '카드 추가하기',
                },
            },
            collects: {
                like: '좋아요',
                loading: '이미지 로드중',
                noMore: '더 이상 표시할 수 없습니다.',
                merchandise: '상품 {0}',
                creative: '창의적인 {0}',
                recommendForYou: '추천',
                all: '모두',
                selected: '선택 {0}',
                delete: '삭제',
                noSelectCreative: '선택된 테마가 없습니다.',
                noSelectGoods: '선택된 상품이 없습니다.',
                delRemindersContent: '알림 내용을 삭제하시겠습니까?',
                delGoodsRemindersContent: '이 {0} 제품을 삭제하시겠습니까?',
                canceledSuccessfully: '취소 성공',
            },
            comments: {
                beEvaluatedValue: '{0} 제품 리뷰 대기',
                evaluated: '작성한 리뷰',
                rule1: '평가 준칙',
                loading: '이미지 로드중',
                noMore: '더 이상 리뷰할 항목이 없습니다~',
                noMore1: '리뷰가 업로드 되었습니다.',
            },
            logistic: {
                loosing: '새로고침 가능',
                loading: '이미지 로드중',
                noMore: '더 이상 표시할 수 없습니다.',
                emptyMsg: '아직 배송 정보가 없습니다.',
            },
            order: {
                detail: {
                    orderStatus: {
                        1: '결제 대기',
                        2: '배송준비',
                        3: '배송중',
                        4: '리뷰 작성',
                        5: '구매확정',
                        6: '취소됨',
                        7: '교환/반품',
                        8: '배송준비중',
                    },
                    piece: '수량',
                    afterSalesProgress: 'CS 진행 내역',
                    productPrice: '제품 가격',
                    freight: '배송비',
                    freeTime1: '제한시간',
                    freeTime2: '무료체험',
                    balance: '남은 잔액',
                    discount: '할인',
                    invoice: '송장',
                    invoiceInfo: '전자 청구서는 현재 지원되지 않습니다.',
                    totalPrice: '합계 금액',
                    orderTime: '주문 시간',
                    orderNo: '주문 번호',
                    paymentMethod: '결제 수단',
                    couponCode: '쿠폰 코드',
                    shipmentNum: '배송번호',
                    deleteOrder: '주문 삭제',
                    payment: '결제',
                    viewLogistics: '택배 조회',
                    confirmReceipt: '구매 확정',
                    applyAfterSales: 'CS 신청중',
                    deleteReminderTitle: '알림 삭제',
                    deleteReminderContent: '이 주문을 삭제 하시겠습니까?',
                    confirmReceiptInfo: '제품을 받으셨습니까?',
                    afterSaleEventStatus: {
                        Refunding: '환불 중',
                        RefundFail: '환불 실패',
                        ReplaceGoodsSucc: '교환 완료',
                        ReplaceGoodsFail: '교환 실패',
                        ReplaceGoodsing: '교환 중',
                        RequestAfterSale: '심사 제출',
                        WaitReview: '심사 대기 중',
                        ReviewResolve: '심사 통과',
                        ReviewReject: '심사 거절',
                        WaitRefund: '환불대기 중',
                        RefundSucc: '환불완료',
                        WaitShip: '배송대기 중',
                        WaitReceiveGoods: '배송 중',
                        Completed: '배송 완료',
                        UserCancel: '사용자 취소',
                    },
                    copySuccess: '복사 성공',
                    payType1: '잔액 + 타사 결제',
                    payType1_1: '잔액 + 카드 결제',
                    payType1_2: '잔액 + KakaoPay',
                    payType1_3: '잔액 + NaverPay',
                    payType1_5: '잔액 + Payco',
                    payType1_6: '잔액 + Toss',
                    payType2: '타사 결제',
                    payType3: '할인 코드 결제',
                    payType4: '잔액지급',
                    payType2_1: '카드 결제',
                    payType2_2: 'KakaoPay',
                    payType2_3: 'NaverPay',
                    payType2_5: 'Payco',
                    payType2_6: 'Toss',
                    payType5: '할인코드 + 잔액지급',
                    payType6: '할인코드 + 잔액 + 타사 결제',
                    payType6_1: '할인코드 + 잔액 + 카드결제',
                    payType6_2: '할인코드 + 잔액 + KakaoPay',
                    payType6_3: '할인코드 + 잔액 + NaverPay',
                    payType6_5: '할인코드 + 잔액 + Payco',
                    payType6_6: '할인코드 + 잔액 + Toss',
                    payType7: '할인코드 + 타사 결제',
                    payType7_1: '할인코드 + 카드결제',
                    payType7_2: '할인코드 + KakaoPay',
                    payType7_3: '할인코드 + NaverPay',
                    payType7_5: '할인코드 + Payco',
                    payType7_6: '할인코드 + Toss',
                    confirmReceiptSuccess: '제품을 수령했습니다.',
                    delSuccess: '삭제 완료',
                },
                list: {
                    searchInfo: '주문 검색',
                    all: '전체',
                    orderStatus: {
                        1: '결제 대기',
                        2: '배송준비',
                        3: '배송중',
                        4: '리뷰 작성',
                        5: '구매확정',
                        6: '취소됨',
                        7: '교환/반품',
                        8: '배송준비중',
                    },
                    loading: '이미지 로드중',
                    noMore: '더 이상 표시할 수 없습니다.',
                    orderNoMoreinfo: '주문 내역 없음',
                    deleteOrder: '주문 삭제',
                    deleteReminderTitle: '알림 삭제',
                    deleteReminderContent: '이 주문을 삭제 하시겠습니까?',
                    confirmReceipt: '구매 확정',
                    confirmReceiptInfo: '제품을 받으셨습니까?',
                    delSuccess: '삭제 완료',
                    confirmReceiptSuccess: '제품을 수령했습니다.',
                },
                logistic: {
                    piece: '수량',
                    viewAllOrders: '모든 주문 보기',
                    logistics_1: '상품 확인',
                    logistics_2: '상품 이동중',
                    logistics_3: '배송지 도착',
                    logistics_4: '배송 출발',
                    logistics_5: '배송 완료',
                    orderTime: '주문 시간',
                    orderNo: '주문 번호',
                    paymentMethod: '결제 수단',
                    shipmentNum: '배송번호',
                    copySuccess: '복사 성공',
                    payType1: '잔액 + 타사 결제',
                    payType1_1: '잔액 + 카드 결제',
                    payType1_2: '잔액 + KakaoPay',
                    payType1_3: '잔액 + NaverPay',
                    payType1_5: '잔액 + Payco',
                    payType1_6: '잔액 + Toss',
                    payType2: '타사 결제',
                    payType3: '할인 코드 결제',
                    payType4: '잔액지급',
                    payType2_1: '카드 결제',
                    payType2_2: 'KakaoPay',
                    payType2_3: 'NaverPay',
                    payType2_5: 'Payco',
                    payType2_6: 'Toss',
                    payType5: '할인코드 + 잔액지급',
                    payType6: '할인코드 + 잔액 + 타사 결제',
                    payType6_1: '할인코드 + 잔액 + 카드결제',
                    payType6_2: '할인코드 + 잔액 + KakaoPay',
                    payType6_3: '할인코드 + 잔액 + NaverPay',
                    payType6_5: '할인코드 + 잔액 + Payco',
                    payType6_6: '할인코드 + 잔액 + Toss',
                    payType7: '할인코드 + 타사 결제',
                    payType7_1: '할인코드 + 카드결제',
                    payType7_2: '할인코드 + KakaoPay',
                    payType7_3: '할인코드 + NaverPay',
                    payType7_5: '할인코드 + Payco',
                    payType7_6: '할인코드 + Toss',
                },
            },
            coupons: {
                coupons: '보유 쿠폰',
                loading: '이미지 로드중',
                noMore: '더 이상 표시할 수 없습니다.',
                off: '할인',
                validityPeriod: '유효기간 :',
                emptyMsg: '현재 사용 가능한 쿠폰이 없습니다.',
                couponCode: '쿠폰 코드 :',
            },
        },
        userInfo: {
            title: '내 정보',
            more: '더 보기',
            collapse: '접기',
            fans: '팬',
            followers: '관심사',
            isArtist: '아티스트',
            wallet: '지갑',
            cart: '장바구니',
            order: '주문',
            comment: '리뷰',
            like: '좋아요',
            artWorks: '나의 작품',
            viewMore: '더 보기',
            notice: '배송 현황',
            history: '방문 기록 찾아보기',
            setUp: '설정',
            artworkList: {
                loading: '이미지 로드중',
                noMore: '더 이상 표시할 수 없습니다.',
            },
            coupons: '쿠폰',
            following: {
                fans: '팔로워',
                following: '팔로잉',
                follow: '팔로우',
                followers: '팔로워',
                noOneFans: '아직 아무도 나를 팔로우하지 않습니다.',
                noOneOtherFans: '아직 Ta를 팔로우하는 사람이 없습니다.',
                noOneFollowers: '아직 팔로워가 없습니다.',
                noOneOtherFollowers: '아직 팔로워가 없습니다.',
                loading: '이미지 로드중',
                dontFollow: '더 이상 관심 없음?',
                followSuccess: '필로우 성공',
                noOneOther: '아무도 팔로우 하지 않았습니다.',
            },
        },
        withdraw: {
            bankCard: '카드 ({0})',
            addBankCard: '카드 추가하기',
            withdrawalAmount: '출금 금액',
            minimumWithdrawal: '최소 출금 한도',
            availableBalance: '사용 가능한 잔액',
            all: '모두',
            withdraw: '출금',
            withdreawalTips: '출금 안내',
            withdreawalTipsContent: '계정 보안을 위해 스마트폰 번호 또는 이메일 주소를 연동하고 확인 후 출금하시기 바랍니다',
            withdreawalTipsConfirm: '연동하기',
            result: {
                withdrawSuccessfully: '출금 성공',
                withdrawSuccessfullyContent: '출금 신청이 검토 중입니다. 잠시 기다려 주십시오',
                details: '세부정보',
                backWallet: '결제 취소',
            },
            resultError: {
                withdrawalFailed: '결제 실패',
                withdrawalFailedDis: '결제 실패, 다시 시도 바랍니다.',
                back: '뒤로',
            },
        },
        404: {
            404: '페이지를 찾을 수 없습니다. 새로고침하려면 클릭하세요.',
            back: '뒤로',
        },
        aihistory: {
            history: '히스토리',
            loading: '이미지 로드중',
            loosing: '새로고침 가능',
            noMore: '더 이상 표시할 수 없습니다.',
            hisEmptyInfo: '현재 기록 없음',
            collectRet: '모음 성공',
            collectCancel: '즐겨찾기 취소',
        },
        appeal: {
            appealTitle: '항소',
            appealDes: '이의신청 내용 설명',
            placeInput: '내용을 입력하세요.',
            appealUpload: '증거 업로드',
            submit: '최근 보기',
            submitSuccess: '제출 성공',
        },
        index: {
            phoneCase: '스마트폰 케이스',
            coLabs: '캐릭터 협업',
        },
        login: {
            tel: '휴대폰 번호',
            telErrTip: '휴대폰 번호를 정확하게 입력해 주세요.',
            email: '이메일',
            emailErrTip: '유효한 이메일을 입력해 주세요.',
            verificationCode: '인증번호',
            getVerificationCode: '인증번호 확인',
            pwd: '비밀번호',
            pwdErrTip: '비밀번호 길이는 6~20자로 제한됩니다',
            telLogin: '휴대폰 번호로 로그인',
            emailLogin: '이메일 로그인',
            pwdLogin: '비밀번호 로그인',
            verificationCodeLogin: '인증번호 로그인',
            simpleLogin: '간편 로그인',
            login: '로그인',
            signUp: '가입하기',
            phoneNumberVerification: '휴대폰 번호로 가입',
            emailVerification: '이메일로 가입',
            passwordsNitMatch: '비밀번호가 일치하지 않습니다',
            confirmPwd: '비밀번호 확인',
            userPrivacyAlerts: '사용자 개인정보 경고',
            agreement1: `로그인함으로써 귀하는 당사의`,
            agreement2: ` “개인정보 처리방침”`,
            agreement3: ` 및`,
            agreement4: ` “서비스 이용 약관”`,
            dialogAgreement: '다음을 읽고 동의해 주십시오',
            dialogAgree: '동의하고 계속하기',
            userAgreement: '서비스 이용 약관',
            privacyAgreement: '개인정보 처리방침',
        },
        me: {
            mine: '마이',
            fans: '팔로워',
            follow1: '팔로우',
            integral: '포인트',
            myWallet: '나의 지갑',
            cart: '장바구니',
            commodityCollects: '제품 컬렉션',
            browsingHistory: '검색 기록',
            beEvaluated: '리뷰 작성',
            myOrder: '주문 내역',
            all: '전체',
            orderStatus: {
                1: '결제 대기',
                2: '배송준비',
                3: '배송중',
                4: '리뷰 작성',
                5: '구매확정',
                6: '취소됨',
                7: '교환/반품',
                8: '배송준비중',
            },
            loading: '이미지 로드중',
            loosing: '새로고침 가능',
            noMore: '더 이상 표시할 수 없습니다.',
            orderNoMoreinfo: '주문 내역 없음',
        },
        message: {
            message: '메시지',
            getMessage: '{0} 개 메시지',
            friendMessage: '친구 메시지',
            followMessage: '메시지 표시',
            likeMessage: '메시지 좋아요',
            commentMessage: '댓글',
            clearRead: '읽은 표시 여부',
        },
        modifyEmail: {
            modifyEmail: '이메일 수정',
            emailBinding: '이메일 인증',
            emailAuthentication: '이메일 인증',
            telAuthentication: '번호 인증',
            verificationCode: '인증번호',
            getVerificationCode: '인증번호 확인',
            newEmail: '새 이메일',
            email: '이메일',
            emailErrTip: '유효한 이메일을 입력해주세요',
            confirm: '확인',
        },
        modifypwd: {
            setPwd: '비밀번호 설정',
            modifyPwd: '비밀번호 수정',
            emailAuthentication: '이메일 인증',
            telAuthentication: '번호 인증',
            verificationCode: '인증번호',
            getVerificationCode: '인증번호 확인',
            pwdErrTip: '비밀번호 길이는 6~20자로 제한됩니다',
            pwd: '비밀번호',
            confirmPwd: '비밀번호 확인',
            confirm: '확인',
        },
        modifytel: {
            emailAuthentication: '이메일 인증',
            telAuthentication: '번호 인증',
            verificationCode: '인증번호',
            getVerificationCode: '인증번호 확인',
            telBinding: '번호 인증',
            modifyTel: '휴대폰 번호 수정',
            verificationCode1: '인증번호',
            getVerificationCode1: '인증번호 확인',
            tel: '휴대폰 번호',
            newTel: '새 휴대폰 번호',
            confirm: '확인',
            telErrTip: '휴대폰 번호를 정확하게 입력해 주세요.',
        },
        points: {
            details: '세부정보',
            pointsAvailable: '사용 가능한 포인트',
            memberTasks: '회원 목록',
            register: '베타 회원 등록',
            completeRegistration: '베타 회원 등록시 별도의 포인트를 지급해 드립니다.',
            completed: '완료',
            comingSoon: 'Cooming Soon',
            completeMaterial: '정보',
            completePersonalInformation: '개인정보',
            levelUp: 'levelUp',
            becomeLevelMember: '회원 가입',
            dailyLogin: '로그인',
            dailyLoginC: '포인트를 확인하시려면 로그인 하세요',
            share: '공유 작품 수',
            shareC: '더 많은 컨텐츠를 공유하고 포인트를 받으세요',
        },
        profileedit: {
            man: '남성',
            woman: '여성',
            username: '이름',
            signature: '설명',
            hobby: '요즘 관심있는 컨텐츠를 선택해 주세요  (ex. 만화, 웹툰, 연예인 등)',
            confirm: '확인',
        },
        setting: {
            basicSettings: '기본 설정',
            email1: '이메일(e-mail)',
            loginPwd: '비밀번호',
            tel: '휴대폰 번호',
            noSetting: '설정되지 않음',
            noBinding: '연결되지 않음',
            modify: '수정',
            bindingInfo: '연결 정보',
            general: '일반 설정',
            selectLang: '언어 설정',
            userAgreement: '서비스 이용 약관',
            privacyAgreement: '개인정보 처리방침',
            cancelAccount: '계정 삭제',
            exitLogin: '로그아웃',
            cancelAccountTip: '계정 해지 확인을 눌러주세요. 해지 후에는 해당 계정을 사용할 수 없으며 복원할 수 없습니다.',
            lang: {
                en_US: 'English',
                ko_KR: '한국어',
            },
            logoutDialogTitle: '로그아웃',
            logoutDialogMessage: '로그아웃 하시겠습니까?',
            initSettingPwd: '먼저 이메일 주소 또는 휴대전화 번호를 입력하세요',
        },
    },
    utils: {
        estimateTime: {
            arrivalTimeValue1: '오늘({0})내 도착 예상',
            arrivalTimeValue2: '내일({0})내 도착 예상',
            arrivalTimeValue3: '모레({0}) 내 도착 예상',
            arrivalTimeValue4: '{0}전 예상',
            arrivalTimeValue5: '{0}예상',
        },
        http: {
            serveError: '네트워크 오류, 나중에 다시 시도하십시오.',
        },
        index: {
            hoursAgo: '{0} 시간 전에',
            minAgo: '{0} 분 전',
            secondsAgo: '{0} 초 전',
            yesterday: '어제',
            sunday: '일요일',
            monday: '월요일',
            tuesday: '화요일',
            wednesday: '수요일',
            thursday: '목요일',
            friday: '금요일',
            saturday: '토요일',
        },
    },
};
