import { defineStore } from 'pinia'
// import { useApiGetUserInfo } from '@/composables/client'
interface GoodLinkConfig {
  id: string
  links: {
    url: string
    link: string
    index: number
  }[]
}
export type GlobalConfigStore = {
  config: {
    imgCacheTime?: number // get图片过期时间
    obsGetCacheTime?: number // get图片过期时间
    enablePayco?: boolean // 是否启用payco支付
    enableTossPay?: boolean // 是否启用toss支付
    topTipsBar?: {
      enable: boolean // 是否显示顶部提示条
      tip: Array<string>
      timeRange: Array<string>
      linkUrl: string
      linkWord: Array<string>
    }
    presaleTipsBar?: {
      enable: boolean // 是否显示预售提示条
      tip: Array<string>
    }
    utmRet?: boolean // 如果是utm链接判断是否上报
    payResultShowGoods?: boolean // 支付结果页显示商品
    payResultShowInsLink?: boolean // 支付结果页显示ins链接
    ipArea?: string // ip区域
    ipAreaAllow?: boolean // ip区域是否允许访问
    goodsLink?: GoodLinkConfig[]
    eventConfig?: {
      sendStep?: number // 发送间隔
    }
  }
}
export const useGlobalConfigStore = defineStore({
  id: 'globalConfigV2',
  state: (): GlobalConfigStore => {
    return {
      config: {},
    }
  },
  actions: {
    setConfig(configData: any) {
      window.globalConfig = configData
      this.config = configData
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
})
