<template>
  <div>
    <Html>
      <Head>
        <Meta charset="UTF-8" />
        <Link rel="icon" href="/favicon.ico" />
        <Meta name="viewport" content="width=device-width,user-scalable=no,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0" />
        <Title>{{ $t('h5.app.title') }}</Title>
      </Head>
    </Html>
    <Body ontouchstart="">
      <Loading ref="loadingRef" />
      <NuxtLoadingIndicator color="#1337bd" />
      <van-config-provider :theme-vars="themeVars">
        <div :key="`app-container-${lang}`" :class="`app-container ${key}`">
          <NuxtPage v-if="!showIpLimit" :keepalive="{ include: ['HomePage', 'GoodsMall', 'Ogrinality'], exclude: ['/cart', 'CartPage'] }"></NuxtPage>
          <IpLimitPageCom v-if="showIpLimit" />
        </div>
      </van-config-provider>
    </Body>
  </div>
</template>
<script setup lang="ts">
import { useUserStore, useGlobalConfigStore, useAttrStore } from '@/stores'

import enUS from 'vant/es/locale/lang/en-US'
import koKR from 'vant/es/locale/lang/ko-KR'
import useSettimeout from '@/hooks/useSettimeout'
import { useApiClientGetAttrList } from '@/composables/order'
import type { ConfigProviderThemeVars } from 'vant'
import { setToastDefaultOptions } from 'vant'
import { Locale } from 'vant'
import { v4 as uuid } from 'uuid'
import { docCookies } from '@/utils/cookie'
import { isSupportWebp } from '@/utils/usebase'
import { getDomain } from '@/utils'
const IpLimitPageCom = defineAsyncComponent(() => import('@/components/ipLimitPage/index.vue'))

const route = useRoute()
const router = useRouter()
const loadingRef = ref()
const commonJs = {
  src: `/js/dataAcquisition_build.js`,
  onload() {
    if (userStore.token && !userStore.userInfo) {
      try {
        ;(window as any).dataAc?.postData()
      } catch (error) {
        console.warn('dataAc not found')
      }
    }
  },
}
const scripts =
  import.meta.env.VITE_MODE == 'prod'
    ? [
        commonJs,
        {
          innerHTML: `(function(n,e,r,t,a,o,s,i,c,l,f,m,p,u){o="precollect";s="getAttribute";i="addEventListener";c="PerformanceObserver";l=function(e){f=[].slice.call(arguments);f.push(Date.now(),location.href);(e==o?l.p.a:l.q).push(f)};l.q=[];l.p={a:[]};n[a]=l;m=document.createElement("script");m.src=r+"?aid="+t+"&globalName="+a;m.crossorigin="anonymous";e.getElementsByTagName("head")[0].appendChild(m);if(i        in        n){l.pcErr=function(e){e=e||n.event;p=e.target||e.srcElement;if(p        instanceof        Element||p        instanceof        HTMLElement){n[a](o,"st",{tagName:p.tagName,url:p[s]("href")||p[s]("src")})}else{n[a](o,"err",e.error||e.message)}};l.pcRej=function(e){e=e||n.event;n[a](o,"reject",e.reason||e.detail&&e.detail.reason)};n[i]("error",l.pcErr,true);n[i]("unhandledrejection",l.pcRej,true)}if("PerformanceLongTaskTiming"in        n){u=l.pp={entries:[]};u.observer=new        PerformanceObserver(function(e){u.entries=u.entries.concat(e.getEntries())});u.observer.observe({entryTypes:["longtask"]})}})(window,document,"https://apm.volccdn.com/mars-web/apmplus/web/browser.cn.js",0,"apmPlus");`,
        },
        {
          innerHTML: `
      window.apmPlus('init', {
        aid: 1000023,
        token: '170ff0a2848041aa9e2aeef267950b3e',
        domain: 'https://apmplus.ap-southeast-1.volces.com',
        release: '1.0.2-h5',
      });
      window.apmPlus('start');
      `,
        },
        {
          innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-NPKD7X8K');
      `,
        },
      ]
    : [commonJs]

useHead({
  script: scripts,
  meta: [
    {
      name: 'facebook-domain-verification',
      content: 'hnbokpxi38myiaqe2p5ihsjk4iuwyd', //dev
      // content: 'q6ialu07k9nzr5urr8gep0a5fvuqxi', //线上
    },
  ],
})

const vantLang: any = {
  en_US: {
    key: 'en-US',
    value: enUS,
  },
  ko_KR: {
    key: 'ko-KR',
    value: koKR,
  },
}
const userStore = useUserStore()
const globalConfig = useGlobalConfigStore()
const attrStore = useAttrStore()
userStore.setLang()
const key = ref(uuid())
const lang = computed(() => userStore.lang)
watch(lang, (v) => {
  const vantLangKeys = vantLang[v]
  Locale.use(vantLangKeys.key, vantLangKeys.value)
})

// vant主题变量定义
const themeVars: ConfigProviderThemeVars = {
  buttonPrimaryColor: '#000',
  buttonPrimaryBackground: '#FFC531',
  buttonPrimaryBorderColor: '#FFC531',
  overlayZIndex: 99,
  // buttonDefaultHeight: '30px',
  // button-default-line-height
  // button-default-font-size
  // button-default-color
}
setToastDefaultOptions({
  position: 'bottom',
  className: 'icool-toast',
  // duration: 0,
  wordBreak: 'break-word',
})

const initMqtt = () => {
  mqttClient.client?.end()
  useApiBaseGetMqttToken().then((res) => {
    const VITE_MQTT_URL = import.meta.env.VITE_MQTT_URL as string
    const token = res.data.jwt
    const connectUrl = `${VITE_MQTT_URL}/mqtt`
    const mode = import.meta.env.VITE_MODE as 'dev' | 'test' | 'uat' | 'prod'
    // 连接选项
    const options = {
      clean: false, // true: 清除会话, false: 保留会话
      connectTimeout: 4000, // 超时时间
      // 认证信息
      clientId: `${mode}-icool-user${userStore.userInfo?.userId}-${uuid()}`,
      username: 'icool',
      //从接口中获取 token
      password: token,
    }
    mqttClient.worker = new Worker(new URL('./utils/wqtt.wroker.js', import.meta.url), { type: 'module' })
    mqttClient.worker.postMessage({
      type: 'init',
      options,
      connectUrl,
      mode,
      userId: userStore.userInfo?.userId,
    })
    mqttClient.worker.onmessage = (event: any) => {
      console.log('收到来自worker的消息', event)
      const { data } = event
      if (data.type === 'data') {
        mqttClient.inject[data.templateType]?.(data.msgJson)
      }
    }

    mqttClient.client = {
      end: () => {
        mqttClient.worker?.postMessage({
          type: 'close',
        })
        mqttClient.worker?.terminate()
      },
    }
  })
}
let mqttClient: { init: any; client: any; inject: any; worker: any } = {
  init: initMqtt,
  client: null,
  inject: {},
  worker: null,
}
const showIpLimit = ref(false) // 显示IP限制页面
//页面时长统计
const timeOnPage = ref(new Date().getTime())
const sendTimeOnPage = () => {
  const time = new Date().getTime()
  const duration = (time - timeOnPage.value) / 1000 / 60 //duration单位为分钟
  const params: UMengParams = {
    Um_Key_TimeOnPage: duration.toFixed(2),
    Um_Key_PageUrl: '/',
  }
  sendEvent(UMENGCUSTOMTYPE.Um_Event_TimeOnPage, params)
}
const pageShowHandler = () => {
  timeOnPage.value = new Date().getTime()
}

// 统计日活
const sendActive = () => {
  sendEvent(UMENGCUSTOMTYPE.Um_Event_5sActice, {})
}
const { play, pause } = useSettimeout(sendActive, 5000)

onMounted(() => {
  const tToken = docCookies.getItem('ACUTTOKEN')
  const tUID = docCookies.getItem('ACUTUID')
  const touristCallback = () => {
    sendEvent(UMENGCUSTOMTYPE.Um_Event_VistorVisit, {})
    if (route.path === '/login') {
      sendEvent(UMENGCUSTOMTYPE.Um_Event_Vistor_EnterLogin, {})
    }
    try {
      ;(window as any).dataAc?.postData()
    } catch (error) {
      console.warn('dataAc not found')
    }
  }

  const initFn = async () => {
    // 更新webp支持
    isSupportWebp()
    getAllAttr()
    // 登录用户
    if (userStore.token && userStore.userInfo) {
      setTimeout(() => {
        getActivityCoupon()
      }, 3000)
      initKf()
      mqttClient.init()
      userStore.updateHomePage()
      uploadUserProfile()
      // 兼容埋点之前的用户
      if (!localStorage.getItem('ACUTOKEN')) {
        localStorage.setItem('ACUTOKEN', userStore.token)
      }
    } else if (!userStore.token) {
      // 游客
      if (tToken && tUID) {
        userStore.setToken(tToken)
        localStorage.setItem('ACUTID', tUID)
        touristCallback()
      } else {
        loginTourist({ touristSource: 'H5' }).then((res: any) => {
          // ACUTID给埋点用的
          localStorage.setItem('ACUTID', res.data.userId)
          userStore.setToken(res.data.token)
          try {
            // 设置游客的删除用户的cookie
            docCookies.setItem('ACUTTOKEN', res.data.token, 60 * 60 * 24 * 30, '/', getDomain(), false)
            docCookies.setItem('ACUTUID', res.data.userId, 60 * 60 * 24 * 30, '/', getDomain(), false)
            docCookies.removeItem('ACUUUID', '/', getDomain())
            docCookies.removeItem('ACUUTOKEN', '/', getDomain())
          } catch (e) {}
          touristCallback()
        })
      }
    } else {
      // 游客注册后
      // 兼容埋点之前的用户
      if (!localStorage.getItem('ACUTOKEN')) {
        localStorage.setItem('ACUTOKEN', userStore.token)
      }
    }
  }
  window.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      pageShowHandler()
      play()
    } else {
      sendTimeOnPage()
      pause()
    }
  })

  const nuxtApp = useNuxtApp() as any
  nuxtApp.$setLoadingRef(loadingRef.value)
  getGlobalConfig({ page: location.href }).then((res) => {
    globalConfig.setConfig(res.data)
    try {
      if (res.data?.eventConfig?.sendStep && Number.isSafeInteger(res.data.eventConfig.sendStep) && res.data?.eventConfig?.sendStep > 0) {
        ;(window as any).dataAc?.setSendStep(res.data.eventConfig.sendStep)
      }
    } catch (error) {
      console.warn('dataAc not found')
    }
    if (!res.data.utmRet) {
      sessionStorage.setItem('dataAc_noUtm', 'true')
    } else {
      sessionStorage.removeItem('dataAc_noUtm')
    }
    if (!res.data?.ipAreaAllow) {
      showIpLimit.value = true
    }
  })
  nextTick(() => initFn())
})
onUnmounted(() => {
  mqttClient.client?.end()
  sendTimeOnPage()
})
provide('mqttClient', mqttClient)

const getAllAttr = () => {
  useApiClientGetAttrList().then((res: any) => {
    if (res.data) {
      attrStore.setAttrStore(res.data)
    }
  })
}

const getActivityCoupon = () => {
  useApiGetActivityCoupon().then((res: any) => {
    if (res.data) {
      userStore.setCouponInfo(res.data)
    } else {
      userStore.setCouponInfo(null)
    }
  })
}

watch(
  () => [userStore.lang],
  (newV, oldV) => {
    if (newV[0] !== oldV[0]) {
      // 切换语言后刷新页面  更新`app-container-${lang}`
      if (route.path === '/originality/detail') {
        router.replace('/originality')
      } else {
        window.location.reload()
      }
    }
  },
)

watch(
  () => [route.path],
  () => {
    if (route.path === '/login' && localStorage.getItem('ACUTID')) {
      // 用户信息更新后刷新页面  更新`app-container-${lang}`
      sendEvent(UMENGCUSTOMTYPE.Um_Event_Vistor_EnterLogin, {})
    }
  },
)
</script>
<style async>
/* @import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap'); */
@import url('https://fonts.bunny.net/css?family=inter:400');
</style>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  width: 100%;
  // min-height: 100vh;
  min-width: auto;
  font-family: 'Inter', 'Pretendard', 'SF Pro SC', 'SF Pro Text', 'SF Pro Icons', 'PingFang SC', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}
input[type='number'] {
  -moz-appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
a:link,
a:active,
a:hover,
a:focus,
a:visited {
  text-decoration: none;
  transition: all linear 200ms;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
label,
div,
p,
span,
svg,
a,
button {
  // 去除ios的边框
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
}

// @font-face {
//   font-family: 'Pretendard';
//   font-weight: 600;
//   src: url('/font/Pretendard-SemiBold.ttf');
// }
@font-face {
  font-family: 'Pretendard';
  src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

.app-container {
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
  padding: 0;
  position: relative;
  // min-height: 100vh;
  // box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.1);
}

@supports (-webkit-touch-callout: none) {
  html,
  body {
    min-height: -webkit-fill-available;
  }
  .app-container {
    min-height: -webkit-fill-available;
  }
}

.van-toast.icool-toast {
  --van-toast-position-bottom-distance: 40px;
  --van-toast-max-width: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  min-height: auto;
  width: auto;
  margin: 0 12px;
  padding: 11px 16px;
  border-radius: 2px;
  text-align: left;
  .van-icon {
    img {
      height: 24px;
      width: auto;
    }
  }
  .van-toast__text {
    width: auto;
    margin-top: 0;
  }
}

.cut-transparent-popup {
  background-color: transparent;
}
</style>
<style>
.van-dialog {
  width: 100% !important;
  border-radius: 4px !important;
}
.van-list__finished-text {
  font-size: 14px !important;
}
</style>
