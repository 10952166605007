export default {
    app: {
        title: 'iCOOL',
    },
    error: {
        back: 'Back',
        notFound: 'Page Not Found, please click to reload',
    },
    components: {
        artists: {
            artistsInfo: {
                noGoods: 'Related products on the shelves~',
                follow: 'Follow',
                unFollow: 'Unfollow',
            },
            singleItem: {
                follow: 'Follow',
                unFollow: 'Unfollow',
            },
        },
        base: {
            cotdPreSale: {
                info: '*This product is on pre-sale and will ship within 7 days of your order.*',
            },
            addBankcardModal: {
                addBankCard: 'Add bank card',
                submit: 'Submit',
            },
            billDetails: {
                details: 'Details',
                arrivalTime: 'Arrival time',
                withdrawTime: 'Withdraw time',
                withdrawType: 'Withdraw type',
                withdrawID: 'Withdraw ID',
                transactionType: 'Transaction type',
                orderAmount: 'Order amount',
                coolProductPricing: 'Cool product pricing',
                orderID: 'Order ID',
            },
            dialog: {
                reminders: 'Reminders',
                confirm: 'Confirm',
                cancel: 'Cancel',
            },
            editAddress: {
                addressInformation: 'Address Information',
                cancel: 'Cancel',
                save: 'Save',
                receiver: 'Receiver',
                name: 'Name',
                tel: 'Tel',
                area: 'Area',
                addArea: 'Add shipping address',
                address: 'Address',
                enterAddress: 'Enter detailed address',
                defaultAddress: 'Default address',
                modifySuccess: 'Modify Success',
                ruleReceiver: 'Receiver',
            },
            empty: {
                noData: 'Nothing yet',
            },
            footer: {
                contactiCOOL: 'Subscribe to iCOOL Newsletter.',
                emailAddress: 'Email address',
                join: 'Submit',
                CopyrightByiCOOL: 'Copyright by iCOOL. All Rights Reserved',
                business1: 'Business Registration: 172-81-03373',
                business2: 'Mail Order License: 2024-부천오정-0187',
                home: 'Home',
                shop: 'Shop',
                creative: 'Creative',
                cart: 'Cart',
                personCenter: 'Personal Center',
                aboutiCOOL: 'About iCOOL',
                aboutUs: 'About Us',
                contactUs: 'Contact Us',
                helpCenter: 'Help Center',
                serviceAgreement: 'Service Agreement',
                privacyAgreement: 'Privacy Agreement',
                exchangeReturnPolicy: 'Exchange Return Policy',
                deliveryPolicy: 'Delivery Policy',
                faq: 'FAQ',
                emailErrTip: 'Please enter a valid email',
                submitSuccess: 'Submit successfully',
                address1: 'Address: 3F 72, Samjak-ro 95beon-gil, Ojeong-gu, Bucheon-si, Gyeonggi-do, Republic of Korea',
                address2: '22F 83, Uisadang-daero, Yeongdeungpo-gu, Seoul, Republic of Korea',
                name1: 'Name of representative: ',
                name2: '이호천 (David Lee)',
                phone1: 'Contact: ',
                phone2: '01068571728',
                phoneInfo: '(Should you have any inquiries, please do not hesitate to contact us via email.)',
                email1: 'Email: ',
                email2: `customer{'@'}i.cool`,
                event: 'Event',
            },
            header: {
                home: 'Home',
                shop: 'Shop',
                design: 'Design',
                creative: 'Creative',
                like: 'Like',
                order: 'Order',
                setup: 'Setup',
                logout: 'Logout',
                en_US: 'English',
                ko_KR: '한국어',
                reminders: 'Reminders',
                logoutConfirm: 'Are you sure you want to log out?',
                confirm: 'Confirm',
                cancel: 'Cancel',
                event: 'Event',
            },
            noticeBar: {
                clickToView: 'SHOP',
                useIt: 'USE NOW',
            },
            recommend: {
                recommendYou: 'Recommend to you',
            },
            selectAddressModal: {
                addArea: 'Add shipping address',
            },
            share: {
                shareToThirdParties: 'Share to third parties',
                shareSuccess: 'Share success',
            },
            textEllipsis: {
                expand: '\nExpand',
                collapse: '\nCollapse',
            },
            tel: {
                tel: 'Tel',
            },
            uploadImg: {
                onlyAcceptImg: 'Only images are allowed.',
                onlyAcceptPng: 'Only PNG and JPEG images are allowed.',
            },
        },
        featuredCreative: {
            creativeItem: {
                buyNow: 'Buy Now',
                loading: 'Loading',
                reply: 'Reply',
                comment: 'Comment',
                seeMore: 'See More',
                shareSuccessTip: 'Copied successfully, go and paste it~',
            },
            detailModal: {
                empty: 'This creative content is no longer valid',
                back: 'Back',
                unFollow: 'Unfollow',
                follow: 'Follow',
                phone: 'Phone',
                loading: 'Loading',
                comment: 'Comment',
                noMore: 'No more',
                shareSuccessTip: 'Copied successfully, go and paste it~',
            },
            reviewItem: {
                likeRet: 'Successfully added',
                reply: 'Reply',
                loading: 'Loading',
                openReply: 'Open {0} Replies',
            },
            toReview: {
                send: 'Send',
                reply: 'Reply',
                comment: 'Comment',
            },
        },
        goods: {
            canUsePayment: {
                payment: 'Quick Payment Methods',
            },
            productSort: {
                default: 'Synthesize',
                saleDESC: 'Highest-selling',
                priceDESC: 'Price high to low',
                priceASC: 'Price low to high',
            },
            sku: {
                options: 'Options',
                placeholder: 'Please Select',
                quantity: 'Quantity',
                addToCart: 'Add to cart',
                buyNow: 'Buy Now',
                pleaseChoose: 'Please choose {0}',
                addSuccess: 'Successfully added',
                cotdInfo1: '*Please choose one cover',
                cotdInfo: '*Please choose cover',
                backplane: 'Cover',
                fameColor: 'Color',
                by: 'By',
                tagv1: '30-day free return and exchange for your first order',
                tagv2: 'Printed in Korea',
                tagv3: 'Free Shipping',
                tagv4: 'Non-pre-sale orders placed on weekdays will arrive within 2 business days. Weekend orders will arrive within 3-4 business days',
            },
            skuModal: {
                options: 'Options',
                placeholder: 'Please Select',
                quantity: 'Quantity',
                addToCart: 'Add to cart',
                buyNow: 'Buy Now',
                confirm: 'Confirm',
                pleaseChoose: 'Please choose {0}',
                addSuccess: 'Successfully added',
                cotdInfo1: '*Please choose one cover',
                cotdInfo: '*Please choose cover',
                backplane: 'Cover',
                fameColor: 'Color',
            },
            artistOthreProducts: {
                otherList: 'Recommended Products',
                seeAll: 'See all',
            },
        },
        home: {
            artists: {
                phoneCase: 'Phone Cases',
                coLabs: 'All Character Collaborations',
            },
            design: {
                title: 'Custom Templates',
            },
            designItem: {
                useIt: 'Use It',
            },
            hotProducts: {
                seeMore: 'See More',
            },
            originality: {
                featuredCreative: 'Creative',
            },
            originalityItem: {
                likeRet: 'Successfully added',
            },
            productItem: {
                buyNow: 'Buy Now',
            },
            series: {
                viewThisSeries: 'Buy It Now',
            },
        },
        order: {
            afterSaleProgress: {
                details: 'Details',
                RefundSucc: 'Refund successful',
                RefundFail: 'Refund failed',
                Refunding: 'Refunding',
                ReplaceGoodsSucc: 'Replacement completed',
                ReplaceGoodsFail: 'Replacement failed',
                ReplaceGoodsing: 'Replacement in progress',
                RequestAfterSale: 'Submit for review',
                WaitReview: 'Waiting for review',
                ReviewResolve: 'review passed',
                ReviewReject: 'Review rejection',
                WaitRefund: 'Waiting for refund',
                WaitShip: 'Waiting for shipment',
                WaitReceiveGoods: 'Waiting for receipt',
                Completed: 'completed',
                UserCancel: 'User Cancel',
                refundSuccTxt: 'The iCOOL platform has processed your refund, which is expected to be credited within 7 business days.',
            },
            goodsInfo: {
                productPrice: 'Product price',
                freeTime1: 'Limited time',
                freeTime2: 'Free',
                balance: 'Balance',
                discount: 'Discounts',
                invoice: 'invoice',
                invoiceInfo: 'Not supported',
                totalPrice: 'Total amount payable',
                orderTime: 'Order time',
                orderNo: 'Order No',
                paymentMethod: 'Payment method',
                couponCode: 'Coupon Code',
                shipmentNum: 'Shipment Num',
                copySuccess: 'Copy successful',
                payType1: 'Balance + Third-party Payment',
                payType1_1: 'Balance + Card Payment',
                payType1_2: 'Balance+ KakaoPay',
                payType1_3: 'Balance + NaverPay',
                payType1_5: 'Balance + Payco',
                payType1_6: 'Balance + Toss',
                payType2: 'Third-party Payment',
                payType3: 'Discount Code Payment',
                payType4: 'Balance Payment',
                payType2_1: 'Card Payment',
                payType2_2: 'KakaoPay',
                payType2_3: 'NaverPay',
                payType2_5: 'Payco',
                payType2_6: 'Toss',
                payType5: 'Discount Code + Balance Payment',
                payType6: 'Discount Code + Balance + Third-party Payment',
                payType6_1: 'Discount Code + Balance + Card Payment',
                payType6_2: 'Discount Code + Balance + KakaoPay',
                payType6_3: 'Discount Code + Balance + NaverPay',
                payType6_5: 'Discount Code + Balance + Payco',
                payType6_6: 'Discount Code + Balance + Toss',
                payType7: 'Discount Code + Third-party Payment',
                payType7_1: 'Discount Code + Card Payment',
                payType7_2: 'Discount Code + KakaoPay',
                payType7_3: 'Discount Code + NaverPay',
                payType7_5: 'Discount Code + Payco',
                payType7_6: 'Discount Code + Toss',
                freight: 'Freight',
            },
        },
        plays: {
            c: {
                material: {
                    background: {
                        stencil: 'Stencil',
                        background: 'Background',
                    },
                    collectionList: {
                        emptyInfo: 'There is currently no related material library',
                    },
                    hasThirdCategory: {
                        back: 'Back',
                    },
                    index: {
                        library: 'Library',
                        officialSelection: 'Official Selection',
                        myCollection: 'My Collection',
                        myUploads: 'My Uploads',
                    },
                    offerList: {
                        back: 'Back',
                    },
                    offerListItem: {
                        emptyInfo: 'There is currently no related material library',
                    },
                    uploadList: {
                        emptyInfo: 'There is currently no related material library',
                        uploadImage: 'Upload Image',
                        delMaterialTitle: 'Reminders',
                        delMaterialContent: 'Are you sure you want to delete the image?',
                    },
                    noMore: {
                        noData: 'Nothing yet',
                    },
                },
                libraryFontBox: {
                    font: 'Font',
                    text: 'Text',
                    generate: 'Generate',
                    play7TxtInfo: 'Only letters are allowed',
                },
                loadImg: {
                    generationStep1: 'Image/text parsing',
                    generationStep2: 'Matching AI parameters',
                    generationStep3: 'Color filling',
                },
                useCutout: {
                    cutout: 'Cutout',
                },
                usePattern: {
                    pattern: 'Pattern',
                    image: 'Image',
                    copies: 'Copies',
                    type: 'Type',
                    repeat: 'Repeat',
                    lattice1: 'Lattice1',
                    lattice2: 'Lattice2',
                },
                useStyle: {
                    style: 'Style',
                },
                useTxtInput: {
                    useText: 'Use text',
                    text: 'Text',
                    generateResults: 'Generate results',
                    firstGenerate: 'Generate',
                    generationFailed: 'Generation failed',
                },
                webFontBox: {
                    font: 'Font',
                    text: 'Text',
                    color: 'Color',
                    reset: 'Reset',
                    confirm: 'Add',
                },
                preBackground: {
                    selectTemplate: 'Select a template',
                    next: 'Next',
                },
            },
            lens: {
                selectTemplate: 'Select a template',
                next: 'Next',
            },
            config: {
                componentsConfig: {
                    play31Placeholder: 'eg：Rose、Tulip、Daisy、Sunflower、Lotus、Orchid',
                    play31ConfirmText: 'Next',
                    play34Placeholder: 'eg：Ice cream、Sunglasses、Sunhat、Flip flops、ice、juice',
                    play34ConfirmText: 'Next',
                    play32Placeholder: 'eg：Mango、Candy、Vanilla、Strawberry',
                    play32ConfirmText: 'Next',
                    play43Placeholder: 'Enter Something...(Only 3 words)',
                },
            },
            fabric: {
                base: {
                    graffiti: {
                        brush: 'Brush',
                        graffiti: 'Graffiti',
                        size: 'Size',
                        eraser: 'Eraser',
                    },
                    cake: {
                        flavor: 'Flavor',
                        placeholder: 'eg：Mango、Candy、Vanilla、Strawberry',
                        chooseFlavor: 'Reset',
                        next: 'Next',
                        back: 'Back',
                        text: 'Text',
                        confirm: 'Confirm',
                    },
                    lens: {
                        confirm: 'Confirm',
                    },
                    layerControl: {
                        forward: 'Forward',
                        backward: 'Backward',
                        horizontal: 'Horizontal',
                        vertical: 'Vertical',
                        undo: 'Undo',
                        redo: 'Redo',
                        delete: 'Delete',
                        revert: 'Revert',
                    },
                    layerControlLeft: {
                        layers: 'Layers',
                    },
                    text: {
                        textFontPopup: {
                            strokeTab: 'Stroke',
                            none: 'None',
                            black: 'Black',
                            white: 'White',
                            green: 'Green',
                            purple: 'Purple',
                            alpha: 'Alpha',
                            width: 'Thickness',
                        },
                    },
                },
            },
            cutPhoneCase: {
                quit: 'Quit',
                myCase: 'My case',
                font: 'Font',
                case: 'Case',
                library: 'Library',
                cutout: 'Cutout',
                style: 'Style',
                useText: 'Use text',
                pattern: 'Pattern',
                stencil: 'Stencil',
                background: 'Background',
                next: 'Next',
                play22Placeholder: 'Enter Something...({0} words or less)',
                uploadImage: 'Upload image',
                uploadImageInfo: '*Please upload photos.',
                uploadImageInfo1: '*Please upload clear portrait photos.',
                uploadImageInfoAccept: '*Please upload JPEG or PNG images.',
                upload: 'Upload',
                noImgTipTitle: 'Tips',
                noImgTipInfo: 'To ensure quality, please place the image in the operating area',
                noImgTipConfirm: 'I see',
                playsError: 'There is an error in the gameplay. This gameplay is not supported for the time being.',
                play42Info: '*Two photos will be shown front and back',
                play42Info1: '',
                play48Info: '*Please upload two images, no commercial',
                play48Info1: 'images are allowed,no larger than 1M.',
                play48ImgTip1: 'Favorite Style',
                play48ImgTip2: 'To be shifted in style',
            },
            drawPhoneCase: {
                history: 'History',
                generationFailed: 'Generation failed',
                quit: 'Quit',
                myCase: 'My case',
                selectCase: 'Choose case',
                font: 'Font',
                upload: 'Upload',
                style: 'Style',
                library: 'Library',
                cutout: 'Cutout',
                pattern: 'Pattern',
                useText: 'Use text',
                stencil: 'Stencil',
                background: 'Background',
                graffiti: 'Graffiti',
                reset: 'Reset',
                reupload: 'Reupload',
                send: 'Send',
                cutTipTitle: 'Cutout tips',
                cutTipInfo: 'To keep matting, select an image with a clear edge and a body',
                noTip: 'No more prompts',
                noImgTipTitle: 'Tips',
                save: 'Save',
                quitTipInfo: 'Are you sure you want to close it? If you close it, the current operation will not be saved',
                cake: 'Cake',
                lens: 'Fisheye lens',
            },
            mbti: {
                title: '*Please select the MBTI role.',
                temTitle: '*Please select your favorite template style.',
                next: 'Next',
            },
            cake: {
                title: '*Please choose your favorite cake flavor.',
                placeholder: 'eg：Mango、Candy、Vanilla、Strawberry',
                chooseFlavor: 'Choose flavor',
                next: 'Next',
            },
            selectAiStyle: {
                chooseStyle: 'Choose Style',
                useIt: 'Use it',
            },
            selectHisimg: {
                history: 'History',
                hisEmptyInfo: 'Currently there are no records',
                collectRet: 'Collection successful',
                collectCancel: 'Cancel favorites',
            },
            selectPhone: {
                phoneCase: 'iPhone Case',
                filter: 'Filter',
                confirm: 'Confirm',
                selectModel: 'Select Model',
                next: 'Next',
            },
        },
        following: {
            fans: 'Fans',
            followers: 'Followers',
            noOneFans: 'No one is following you yet',
            noOneOtherFans: 'No one is following this person yet',
            noOneFollowers: 'No followers yet',
            noOneOtherFollowers: 'No followers yet',
            loading: 'Loading',
            follow: 'Follow',
            following: 'Following',
            dontFollow: `Sure you don't want to continue?`,
            unFollowConfirm: 'Sure to Unfollow?',
            reminders: 'Reminders',
            confirm: 'Confirm',
            followSuccess: 'Follow Success',
            cancel: 'Cancel',
        },
    },
    pages: {
        artists: {
            index: {
                loading: 'Loading',
                title: 'Featured Creatives',
                all: 'All',
            },
            originality: {
                follow: 'Follow',
                unFollow: 'Unfollow',
                storyTitle: 'Creative Story',
                searchTotal: 'Total {0} related items',
                artist: 'Artist',
            },
        },
        author: {
            index: {
                following: 'Following',
                fans: 'Fans',
                follow: 'Follow',
                unFollow: 'Unfollow',
                loading: 'Loading',
                searchTotal: 'Total {0} Sets of Artwork',
                product: 'Product',
                searchGoodsTotal: 'Total {0} related items',
                artwork: 'Artwork',
            },
        },
        featuredCreative: {
            seeMore: 'See More',
            noMore: 'No More ~',
            loading: 'Loading',
        },
        goods: {
            productExpired: 'This product has expired',
            shop: 'Shop',
            details: 'Details',
            review: 'Review',
            synthesize: 'Synthesize',
            newest: 'Newest',
        },
        person: {
            applyAfterSale: {
                applyAfterSales: 'Apply',
                submitAfterSales: 'Submit after-sales',
                afterSalesExchange: 'Exchange',
                afterSalesReasons: 'After-sales reasons',
                justRefund: 'Just Refund',
                returnRefund: 'Return Refund',
                submit: 'submit',
                placeInput: 'Please enter content',
                submitSuccess: 'Submit successfully',
                returnPolicyTips: 'As this platform is a customized product, refunds are not supported for non-product issues, click to view the',
                returnPolicyBtn: 'Return policy',
                exchangeReturnPolicy: 'Exchange/Return Policy',
                timeoutNotShipped: 'Timeout not shipped',
                timeoutNotReceived: 'Timeout not received',
                receivedGoodsDoNotMatch: 'Received goods do not match the description, damaged or defective goods',
            },
            artWork: {
                totalArtwork: 'Total {0} Artworks.',
                noArtwork: 'No Artworks Yet',
            },
            browseHistory: {
                manage: 'Manage',
                cancel: 'Cancel',
                selectAll: 'Select All',
                goods: 'Goods',
                expired: 'Expired',
                noHistory: 'No Browsing History Yet',
                creative: 'Creative',
                selectDelHistoryTip: 'Please select browsing history to delete',
                deleteReminders: 'Delete Reminders',
                deleteHistoryConfirm: 'Confirm to delete these {0} browsing history?',
                confirm: 'Confirm',
                today: 'Today',
                deleteSuccess: 'Delete Success',
            },
            index: {
                man: 'Man',
                woman: 'Woman',
                confirm: 'Confirm',
                username: 'Username',
                signature: 'Signature',
                hobby: 'Hobby',
                operationSuccess: 'Operation Success',
            },
            like: {
                goods: 'Goods',
                likeGoodsTotalTip: 'Total {0} Liked Goods',
                likeCreativeTotalTip: 'Total {0} Liked Creative',
                expired: 'Expired',
                emptyLike: 'No likes at this time',
                creative: 'Creative',
                canceledSuccessfully: 'Canceled successfully',
                buyNow: 'Buy Now',
                creativeExpired: 'The creative content has expired',
            },
            notice: {
                notice: 'Notice',
                view: 'View',
                emptyMsg: 'Currently there is no logistics notification',
                packageInfo: 'Package Information',
                refundInfoDynamics: 'Refund Dynamics',
                packageDynamics: 'Package Dynamics',
                orderNormalTitle: 'Order Logistics Information',
                orderRefundTitle: 'Order Refund and Exchange Information',
                refundTitleSuccess: 'Your Order Has Been Successfully Refunded',
                refundTitleFaild: 'Your Order Refund Was Unsuccessful',
                bvy: 'Purchase',
                replace: 'Exchange',
                logistics_level_msg_title1: 'Your {0} item has been shipped',
                logistics_level_msg_title3: 'Your {0} item is in transit',
                logistics_level_msg_title6: 'Your {0} item has been delivered',
                refundInfoSuccess: 'Refund Has Been Processed',
                refundInfoFaild: 'Refund Application Was Unsuccessful, Please Address Promptly',
            },
            orderDetail: {
                orderStatus: {
                    1: 'Pending payment',
                    2: 'To be shipped',
                    3: 'On the Way',
                    4: 'To be evaluated',
                    5: 'Completed',
                    6: 'Cancelled',
                    7: 'After sale',
                    8: 'To be shipped',
                },
                edit: 'edit',
                deleteOrder: 'Delete order',
                payment: 'Payment',
                viewLogistics: 'Logistics',
                confirmReceipt: 'Confirm receipt',
                applyAfterSales: 'Apply',
                deleteReminderContent: 'Are you sure you want to delete this order?',
                deleteReminderTitle: 'Delete reminder',
                confirm: 'Confirm',
                cancel: 'Cancel',
                confirmReceiptSuccess: 'Confirm receipt of goods successfully',
                confirmReceiptInfo: 'Have you confirmed receipt of the product? ',
                delSuccess: 'Deletion successful',
                afterSaleEventStatus: {
                    RefundSucc: 'Refund successful',
                    RefundFail: 'Refund failed',
                    Refunding: 'Refunding',
                    ReplaceGoodsSucc: 'Replacement completed',
                    ReplaceGoodsFail: 'Replacement failed',
                    ReplaceGoodsing: 'Replacement in progress',
                    RequestAfterSale: 'Submit for review',
                    WaitReview: 'Waiting for review',
                    ReviewResolve: 'review passed',
                    ReviewReject: 'Review rejection',
                    WaitRefund: 'Waiting for refund',
                    WaitShip: 'Waiting for shipment',
                    WaitReceiveGoods: 'Waiting for receipt',
                    Completed: 'completed',
                    UserCancel: 'User Cancel',
                },
            },
            orderLogistics: {
                logistics_1: 'Goods received',
                logistics_2: 'Goods in transit',
                logistics_3: 'Destination arrived',
                logistics_4: 'Departed for delivery',
                logistics_5: 'Delivery completed',
                logisticsDetails: 'Logistics details',
            },
            order: {
                all: 'All',
                orderStatus: {
                    1: 'Pending payment',
                    2: 'To be shipped',
                    3: 'On the Way',
                    4: 'To be evaluated',
                    5: 'Completed',
                    6: 'Cancelled',
                    7: 'After sale',
                    8: 'To be shipped',
                },
                afterSaleStatus: {
                    '-1': 'User cancels',
                    1: 'Pending',
                    2: 'To be shipped',
                    3: 'On the Way',
                    4: 'Completed',
                    5: 'Audit rejection',
                    6: 'Pending refund',
                    7: 'Refunding',
                },
                deleteOrder: 'Delete order',
                actuallyPaid: 'Actually paid: ',
                orderDetail: 'Order details',
                viewLogistics: 'Logistics',
                confirmReceipt: 'Confirm receipt',
                applyAfterSales: 'Apply',
                afterSalesProgress: 'Progress',
                payment: 'Payment',
                orderNoMoreinfo: 'There are currently no related orders',
                deleteReminderTitle: 'Delete reminder',
                deleteReminderContent: 'Are you sure you want to delete this order?',
                confirm: 'Confirm',
                cancel: 'Cancel',
                delSuccess: 'Deletion successful',
                confirmReceiptInfo: 'Have you confirmed receipt of the product? ',
                confirmReceiptSuccess: 'Confirm receipt of goods successfully',
            },
            review: {
                rule: 'Rule',
                beEvaluated: 'Be evaluated',
                totalBeReviewTxt: 'Total {0} to be reviewed',
                evaluate: 'Evaluate',
                noBeReview: 'No items to review yet',
                totalReviewTxt: 'Total {0} reviews',
                evaluated: 'Evaluated',
                pending: 'Pending',
                rejected: 'Rejected',
                ealuationYet: 'There are no reviews yet',
                evaluationRules: 'Evaluation rules',
                evaluationRulesT1: '1. Evaluating the statute of limitations for tanning.',
                evaluationRulesC1: 'Customers can evaluate their purchases within 60 days of order fulfillment.',
                evaluationRulesT2: '2.the evaluation of the sunshine statement.',
                evaluationRulesC2_1: `1. In order to ensure the objectivity of the evaluation, once the evaluation content is successfully published, I cannot modify or delete it.`,
                evaluationRulesC2_2: `2. If you purchase multiple items of the same product in one order, they will only be evaluated as one item when evaluating.`,
                evaluationRulesC2_3: `3. The evaluation content must not contain link addresses, otherwise it cannot be submitted.`,
                evaluationRulesC2_4: `4. Banned users cannot submit reviews during the ban period and can contact customer service for consultation.`,
                evaluationRulesC2_5: `5. Since you cannot modify or delete the reviewed reviews, please be careful not to involve your or other people’s sensitive personal information when posting reviews.`,
                evaluationRulesC2_6: `6. Hide the evaluation list: If you want to hide the evaluation records in the evaluation center for privacy reasons, you can go to the order list to hide the corresponding order. The evaluation records will be hidden in the to-be-evaluated and evaluated lists simultaneously. Once hidden, they cannot be restored.`,
                evaluationRulesC2_7: `7. Return and exchange evaluation: Return order product evaluation: If there is no evaluation before the return, the order cannot be evaluated after the return; if the evaluation has been completed before the return is completed, the evaluation will be displayed normally after the return. Product evaluation of exchange orders: If there is no evaluation before the exchange, the products of the pre-exchange order can be evaluated after the exchange; regardless of whether the evaluation of the products of the pre-exchange order has been completed, the new order products can be evaluated normally after the exchange; if the products of the pre-exchange order have been evaluated If you also submit a review for the new order products after the exchange, the product reviews of the order before the exchange will not be displayed, and only the product reviews of the order after the exchange will be displayed.`,
                evaluationRulesC2_8: `8. After the evaluation order and evaluation reply are successfully published, in addition to being displayed on the product details page, evaluation center, and personal homepage, they may also be displayed in complete or summary form on the homepage, search, store, and other locations.`,
                evaluationRulesC2_9: `9. Privacy protection: In order to protect user privacy, the evaluation module on the product details page will desensitize and display the asterisk of the user's nickname, such as: Xiao***xi.`,
            },
            writeReview: {
                evaluate: 'Evaluate',
                productScore: 'Product evaluation',
                reviewContent: 'Review Content',
                placeholderTxt: 'Call up the keyboard and write a more helpful review!',
                remarkTxt: 'Reviews will be displayed anonymously',
                submit: 'submit',
                star1: 'Very bad',
                star2: 'Poor',
                star3: 'General',
                star4: 'Good',
                star5: 'Very good',
                evaluationSuccess: 'Evaluation successful',
            },
            coupons: {
                coupons: 'Coupons',
                off: 'OFF',
                validityPeriod: 'Valid until:',
                emptyMsg: 'No coupons available at this time',
                couponCode: 'Coupon Code:',
            },
        },
        setup: {
            account: {
                account: 'Account',
                tel: 'Tel',
                noSetting: 'Not set',
                modify: 'Modify',
                bindingInfo: 'Binding information',
                email: 'Email',
                setting: 'Setting',
                initSettingPwd: 'Please bind your email or mobile phone number first',
                modifyPwd: {
                    pwd: 'Password',
                },
            },
            address: {
                myAddress: 'My address',
                receiver: 'Receiver',
                name: 'Name',
                tel: 'Tel',
                area: 'Area',
                address: 'Address',
                enterAddress: 'Enter detailed address',
                defaultAddress: 'Default address',
                save: 'Save',
                confirm: 'Confirm',
                cancel: 'Cancel',
                addAddress: 'Add address',
                delete: 'Delete',
                edit: 'Edit',
                addSuccess: 'Add Success',
                modifySuccess: 'Modify Success',
                operationSuccess: 'Operation Success',
                addressDelTip: 'Are you sure you want to delete the address?',
                reminders: 'Reminders',
                confirmAddr: 'Confirm',
                cancelAddr: 'Cancel',
                deleteSuccess: 'Delete Success',
            },
            bindingEmail: {
                emailBinding: 'Email binding',
                telAuthentication: 'Tel authentication',
                getVerificationCode: 'Get verification code',
                verificationCode: 'Verification code',
                email: 'Email',
                confirm: 'Confirm',
                sendSuccess: 'Send Success',
                operationSuccess: 'Operation Success',
                emailErrTip: 'Please enter a valid email',
                verificationCodeErrTip: 'Please enter the verification code',
            },
            bindingInformation: {
                bindingInfo: 'Binding information',
            },
            bindingPhone: {
                emailAuthentication: 'Email authentication',
                telBinding: 'Tel binding',
                verificationCode: 'Verification code',
                getVerificationCode: 'Get verification code',
                tel: 'Tel',
                confirm: 'Confirm',
                sendSuccess: 'Send Success',
                operationSuccess: 'Operation Success',
                telErrTip: 'Please enter the correct mobile phone number',
                verificationCodeErrTip: 'Please enter the verification code',
            },
            modifyEmail: {
                modifyEmail: 'Modify email',
                emailAuthentication: 'Email authentication',
                telAuthentication: 'Tel authentication',
                verificationCode: 'Verification code',
                getVerificationCode: 'Get verification code',
                email: 'Email',
                confirm: 'Confirm',
                sendSuccess: 'Send Success',
                operationSuccess: 'Operation Success',
                emailErrTip: 'Please enter a valid email',
                verificationCodeErrTip: 'Please enter the verification code',
            },
            modifyPhone: {
                modifyTel: 'Modify tel',
                emailAuthentication: 'Email authentication',
                telAuthentication: 'Tel authentication',
                verificationCode: 'Verification code',
                getVerificationCode: 'Get verification code',
                newTel: 'New tel',
                confirm: 'Confirm',
                sendSuccess: 'Send Success',
                operationSuccess: 'Operation Success',
                telErrTip: 'Please enter the correct mobile phone number',
                verificationCodeErrTip: 'Please enter the verification code',
            },
            modifyPwd: {
                modifyPwd: 'Change password',
                emailAuthentication: 'Email authentication',
                telAuthentication: 'Tel authentication',
                verificationCode: 'Verification code',
                getVerificationCode: 'Get verification code',
                pwd: 'Password',
                confirmPwd: 'Confirm Password',
                confirm: 'Confirm',
                pwdErrTip: 'Password length limit is 6 to 20 characters',
                sendSuccess: 'Send Success',
                operationSuccess: 'Operation Success',
                passwordsNitMatch: 'Passwords Do Not Match',
                verificationCodeErrTip: 'Please enter the verification code',
            },
            setPwd: {
                setPwd: 'Set password',
                emailAuthentication: 'Email authentication',
                telAuthentication: 'Tel authentication',
                verificationCode: 'Verification code',
                getVerificationCode: 'Get verification code',
                pwd: 'Password',
                confirmPwd: 'Confirm Password',
                confirm: 'Confirm',
                pwdErrTip: 'Password length limit is 6 to 20 characters',
                sendSuccess: 'Send Success',
                operationSuccess: 'Operation Success',
                passwordsNitMatch: 'Passwords Do Not Match',
                verificationCodeErrTip: 'Please enter the verification code',
            },
        },
        wallet: {
            bankCard: {
                myBankCard: 'My bank card',
                card: 'Card',
                addBankCard: 'Add bank card',
                unbind: 'Unbind',
                noBankCard: 'There is currently no bank card',
                addSuccess: 'Add Success',
                unbundleBankCardContent: 'Are you sure you want to unbind the card?',
                unbundleBankCard: 'Unbundle bank card',
                operationSuccess: 'Operation Success',
                confirm: 'Confirm',
                cancel: 'Cancel',
            },
            bill: {
                bill: 'Bill',
                transactionType: 'Transaction type',
                orderAmount: 'Order amount',
                details: 'Details',
                withdraw: 'Withdraw',
                all: 'All',
                income: 'Income',
                purchase: 'Purchase',
                refund: 'Refund',
                noMore: 'No more bills at this time',
                withdraw2: 'Withdraw',
                refund2: 'Refund',
                basefee: 'Base Fee',
                buyOrder: 'Buy Order',
                settlementDividendFee: 'Settlement Dividend Fee',
                settlementCommissionFee: 'Settlement Commission Fee',
            },
            withdrawResult: {
                withdrawSuccessfully: 'Withdraw successfully',
                withdrawSuccessfullyContent: 'The withdrawal has been submitted for review, please wait patiently.',
                details: 'Details',
                goBack: 'Go Back',
            },
            withdraw: {
                withdraw: 'Withdraw',
                bankCard: 'Bank card',
                addBankCard: 'Add bank card',
                withdrawalAmount: 'Withdrawal Amount',
                availableBalance: 'Available Balance',
                minimumWithdrawal: 'Minimum withdrawal limit',
                all: 'All',
                confirm: 'Confirm',
                cancel: 'Cancel',
                tel: 'Tel',
                email: 'Email',
                verificationCode: 'Verification code',
                getVerificationCode: 'Get verification code',
                emailAuthentication: 'Email authentication',
                telAuthentication: 'Tel authentication',
                addSuccess: 'Successfully added',
                withdreawalTips: 'Withdrawal tips',
                withdreawalTipsContent: 'To ensure the security of your account, please bind your mobile phone number or email address and successfully verify it before you can withdraw money.',
                withdreawalTipsConfirm: 'Go to binding',
            },
        },
        404: {
            back: 'Back',
            notFound: 'Page Not Found, please click to reload',
        },
        cart: {
            cart: 'Cart',
            selectAll: 'Select All',
            delete: 'Delete',
            orderSummary: 'Order Summary',
            expired: 'Expired',
            number: 'Number',
            totalPrice: 'Total Price',
            settleOrder: 'Settle Order',
            discount: 'Discounts',
            cartEmptyTip: 'Cart is empty, quickly add your favorite items',
            shop: 'Shop',
            delSku: 'Are you sure you want to delete these {0} products?',
            confirm: 'Confirm',
            cancel: 'Cancel',
            delSuccess: 'Deletion successful',
            deleteReminders: 'Delete Reminders',
        },
        createOrder: {
            addAddress: 'Add Address',
            default: 'Default',
            confirmOrder: 'Confirm Order',
            orderAddress: 'Shipping Address',
            orderGoods: 'Goods Details',
            orderInfo: 'Order Info',
            orderNotes: 'Order Notes',
            orderNotesTip: 'You can write some order notes here',
            expired: 'Expired',
            orderSummary: 'Order Summary',
            number: 'Number',
            freight: 'Freight',
            totalPrice: 'Total Price',
            freeTime1: 'Limited time',
            freeTime2: 'Free',
            discount: 'Discounts',
            enterPromoCode: 'Enter Promo Code',
            validate: 'Validate',
            availableBalance: 'Available Balance',
            deduction: 'Deduction',
            payment: 'Payment',
            back: 'Back',
            redemptionSuccessful: 'Redemption successful',
            invalidPromoCodeInfo: 'This promo code is not valid, please check and try again',
            edit: 'Edit',
        },
        customize: {
            title: 'Custom Templates',
            loading: 'Loading',
            all: 'All',
        },
        login: {
            telLogin: 'Tel login',
            emailLogin: 'Email login',
            pwdLogin: 'Password login',
            verificationCodeLogin: 'Verification code login',
            login: 'Login',
            otherLogin: 'Other login methods',
            email: 'Email',
            pwd: 'Password',
            send: 'Send',
            tel: 'Tel',
            emailErrTip: 'Please enter a valid email',
            telErrTip: 'Please enter the correct mobile phone number',
            pwdErrTip: 'Password length limit is 6 to 20 characters',
            verificationCode: 'Verification code',
            sendSuccess: 'Send Success',
            verificationCodeErrTip: 'Please enter the verification code',
            signUp: 'Sign up',
            phoneNumberVerification: 'Phone number verification',
            emailVerification: 'Email verification',
            confirmPwd: 'Confirm Password',
            passwordsNitMatch: 'Passwords Do Not Match',
            userPrivacyAlerts: 'User Privacy Alerts',
            agreement1: `By signing in you agree to our`,
            agreement2: ` “Privacy agreement”`,
            agreement3: ` and`,
            agreement4: ` “Terms of Service”`,
            dialogAgreement: 'Please read and agree to the following',
            dialogAgree: 'Agree and continue',
            userAgreement: 'Terms of Service',
            privacyAgreement: 'Privacy agreement',
        },
        payResult: {
            paySuccess: 'Payment successful',
            payFail: 'Payment failed',
            paySuccessInfo1: 'Cool products are being customized, we will arrange delivery as soon as possible',
            shop: 'Shop',
            viewOrder: 'View order',
            insTips1: 'After purchasing a product, you must complete a',
            insTips2: 'icool.kr',
            insTips3: 'form within the iCOOL Instagram profile link to be eligible for the drawing.',
            insBtn: 'Go Now',
        },
        // pages/person.vue
        personSingle: {
            following: 'Following',
            isArtist: 'Artist',
            fans: 'Fans',
            like: 'Like',
            myOrder: 'My order',
            myWallet: 'My Wallet',
            artWorks: 'ArtWork',
            browserRecord: 'Browsing history',
            myComments: 'My Comments',
            notice: 'Notice',
            setUp: 'Set up',
            coupons: 'Coupons',
        },
        series: {
            follow: 'Follow',
            unFollow: 'Unfollow',
            title: 'Series',
            coCreativeDesigner: 'Co-Creative Designer',
            searchTotal: 'Total {0} Sets of Artwork',
        },
        //pages/setup.vue
        setupSingle: {
            setUp: 'Set up',
            logOut: 'Log out',
            account: 'Account',
            myAddress: 'My Addresses',
            bindingInformation: 'Binding Information',
            contactUs: 'Contact Us',
            userAgreement: 'User Agreement',
            serviceAgreement: 'Service Agreement',
            privacyAgreement: 'Privacy Agreement',
            exchangeReturnPolicy: 'Exchange Return Policy',
            faq: 'FAQ',
            deliveryPolicy: 'Delivery Policy',
            logoutConfirm: 'Are you sure you want to log out?',
            reminders: 'Reminders',
            confirm: 'Confirm',
            cancel: 'Cancel',
        },
        shop: {
            shop: 'Shop',
            search: 'Search',
            filters: 'Filters',
            fold: 'Show Less',
            unFold: 'Show More',
            loading: 'Loading',
            noSearches: 'No searches found for this product',
            series: 'Series',
            artist: 'Artist',
        },
        wallte: {
            myWallet: 'My Wallet',
            walletBalance: 'Wallet Balance',
            bill: 'Bill',
            bankCard: 'Bank card({0})',
        },
    },
    hooks: {
        useCollectPost: {
            likeSuccess: 'Added to Likes, you can view in "My-Likes"',
            likeCancel: 'Cancel Like Success',
        },
        useCollectProduct: {
            likeSuccess: 'Added to Likes, you can view in "My-Likes"',
            likeCancel: 'Cancel Like Success',
        },
        useFollow: {
            unFollowConfirm: 'Sure to Unfollow?',
            reminders: 'Reminders',
            confirm: 'Confirm',
            cancel: 'Cancel',
            likeCancel: 'Cancel Like Success',
            followSuccess: 'Follow Success',
        },
    },
    utils: {
        estimateTime: {
            arrivalTimeValue1: 'Estimated today ({0})',
            arrivalTimeValue2: 'Estimated before tomorrow ({0})',
            arrivalTimeValue3: 'Estimated day after tomorrow ({0}) ago',
            arrivalTimeValue4: 'Estimated {0} ago',
            arrivalTimeValue5: 'Estimated {0}',
        },
        http: {
            serveError: 'Network abnormality, please try again later',
        },
        index: {
            hoursAgo: '{0} hour ago',
            minAgo: '{0} min ago',
            secondsAgo: '{0} seconds ago',
            emailErrTip: 'Please enter a valid email',
            pwdErrTip: 'Password length limit is 6 to 20 characters',
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            yesterday: 'Yesterday',
            telErrTip: 'Please enter the correct mobile phone number',
        },
        skuUtils: {
            likeSuccess: 'Added to Likes, you can view in "My-Likes"',
            likeCancel: 'Cancel Like Success',
            networkError: 'The network is abnormal, please try again later',
        },
    },
};
