export default {
    app: {
        title: 'iCOOL',
    },
    error: {
        notFound: '요청하신 페이지를 찾을 수 없습니다. 다시 시도해 주세요.',
        back: '뒤로가기',
    },
    components: {
        artists: {
            artistsInfo: {
                noGoods: '관련 상품이 진열대 위에~',
                follow: '팔로우',
                unFollow: '언팔로우',
            },
            singleItem: {
                follow: '팔로우',
                unFollow: '언팔로우',
            },
        },
        base: {
            cotdPreSale: {
                info: '*본 상품은 현재 예약판매 중인 상품으로, 주문 후 7일 이내 배송 될 예정입니다.*',
            },
            addBankcardModal: {
                addBankCard: '카드 추가하기',
                submit: '제출 ',
            },
            billDetails: {
                details: '세부정보',
                arrivalTime: '도착 시간',
                withdrawTime: '출금 시간',
                withdrawType: '출금 유형',
                withdrawID: '출금 ID',
                transactionType: '거래 유형',
                orderAmount: '주문 금액',
                coolProductPricing: '총 가격',
                orderID: '주문 ID',
            },
            dialog: {
                reminders: '알림',
                confirm: '확인',
                cancel: '취소',
            },
            editAddress: {
                addressInformation: '상세 주소',
                cancel: '취소',
                save: '저장',
                receiver: '이름',
                name: '이름',
                tel: '전화번호',
                area: '주소',
                addArea: '기본 주소지',
                address: '상세 주소',
                enterAddress: '상세 주소 입력',
                defaultAddress: '기본 배송지로 설정',
                modifySuccess: '수정 완료',
                ruleReceiver: '받는 사람',
            },
            empty: {
                noData: '아직 관련 컨텐츠가 없습니다.',
            },
            footer: {
                contactiCOOL: 'iCOOL 뉴스레터 구독하기',
                emailAddress: '이메일 주소',
                join: '제출하기',
                CopyrightByiCOOL: '저작권에 대한 모든 권리는 © Icool에 있습니다.',
                business1: '사업자 등록번호: 172-81-03373',
                business2: '통신판매업신고: 2024-부천오정-0187',
                home: '홈',
                shop: '스토어',
                creative: '크리에이티브',
                cart: '장바구니',
                personCenter: '내 정보',
                aboutiCOOL: 'iCOOL 정보',
                aboutUs: '회사 소개',
                contactUs: '문의하기',
                helpCenter: '고객센터',
                serviceAgreement: '서비스 약관',
                privacyAgreement: '개인정보 보호 정책',
                exchangeReturnPolicy: '반품/교환 정책',
                deliveryPolicy: '배송 정책',
                faq: '자주 묻는 질문과 답변',
                emailErrTip: '유효한 이메일을 입력해주세요',
                submitSuccess: '제출 성공',
                address1: '주소: 경기 부천시 오정구 삼작로 95번길 72 3층 ',
                address2: '서울 영등포구 의사당대로 83 오투타워 20층',
                name1: '대표자명: ',
                name2: '이호천 (David Lee)',
                phone1: '전화번호: ',
                phone2: '01068571728',
                phoneInfo: '(문의사항은 이메일 부탁드립니다.)',
                email1: '이메일: ',
                email2: `customer{'@'}i.cool`,
                event: '이벤트',
            },
            header: {
                home: '홈',
                shop: '스토어',
                design: '디자인',
                creative: '크리에이티브',
                like: '좋아요',
                order: '주문',
                setup: '설정',
                logout: '로그아웃',
                en_US: 'English',
                ko_KR: '한국어',
                reminders: '알림',
                logoutConfirm: '로그아웃 하시겠습니까?',
                confirm: '확인',
                cancel: '취소',
                event: '이벤트',
            },
            noticeBar: {
                clickToView: 'SHOP',
                useIt: '바로 사용',
            },
            recommend: {
                recommendYou: '제품 추천',
            },
            selectAddressModal: {
                addArea: '기본 주소지',
            },
            share: {
                shareToThirdParties: '컨텐츠를 공유할 채널을 선택하세요',
                shareSuccess: '공유하기',
            },
            textEllipsis: {
                expand: '\n펼치기',
                collapse: '\n접오두기',
            },
            tel: {
                tel: '휴대폰 번호',
            },
            uploadImg: {
                onlyAcceptImg: '사진만 가능합니다.',
                onlyAcceptPng: 'PNG, JPEG 파일만 가능합니다.',
            },
        },
        featuredCreative: {
            creativeItem: {
                buyNow: '바로 구매',
                loading: '이미지 로드중',
                reply: '답변',
                comment: '댓글',
                seeMore: '더보기',
                shareSuccessTip: '복사가 완료되었습니다, 빨리 붙여넣으세요~',
            },
            detailModal: {
                empty: '이 광고 소재 콘텐츠는 더 이상 유효하지 않습니다.',
                back: '뒤로가기',
                unFollow: '언팔로우',
                follow: '팔로우',
                phone: '휴대전화',
                loading: '이미지 로드중',
                comment: '댓글',
                noMore: '더 이상 표시할 수 없습니다.',
                shareSuccessTip: '복사가 완료되었습니다, 빨리 붙여넣으세요~',
            },
            reviewItem: {
                likeRet: '좋아요',
                reply: '답변',
                loading: '이미지 로드중',
                openReply: '{0}개의 답변 보기',
            },
            toReview: {
                send: '전송',
                reply: '답변',
                comment: '댓글',
            },
        },
        goods: {
            canUsePayment: {
                payment: '간편 결제 방식',
            },
            productSort: {
                default: '종합',
                saleDESC: '판매량 높은 순',
                priceDESC: '가격 높은 순',
                priceASC: '가격 낮은 순',
            },
            sku: {
                options: '옵션',
                placeholder: '선택하세요',
                quantity: '수량',
                addToCart: '장바구니에 추가하기',
                buyNow: '바로 구매',
                pleaseChoose: '선택 {0}',
                addSuccess: '추가 성공',
                cotdInfo1: '*커버 하나를 선택하세요',
                cotdInfo: '*커버를 선택하세요',
                backplane: '커버',
                fameColor: '색상',
                by: 'By',
                tagv1: '첫 주문 30일 무료 반품 교환',
                tagv2: '한국에서 직접 인쇄',
                tagv3: '무료 배송',
                tagv4: '비예약 상품은 평일 주문 시 2일 이내, 주말 주문 시 3-4일 이내에 배송됩니다',
            },
            skuModal: {
                options: '옵션',
                placeholder: '선택하세요',
                quantity: '수량',
                addToCart: '장바구니에 추가하기',
                buyNow: '바로 구매',
                confirm: '확인',
                pleaseChoose: '선택 {0}',
                addSuccess: '추가 성공',
                cotdInfo1: '*커버 하나를 선택하세요',
                cotdInfo: '*커버를 선택하세요',
                backplane: '커버',
                fameColor: '색상',
            },
            artistOthreProducts: {
                otherList: '아티스트의 다른 상품',
                seeAll: 'all',
            },
        },
        home: {
            artists: {
                phoneCase: '스마트폰 케이스',
                coLabs: 'ALL',
            },
            design: {
                title: '커스텀',
            },
            designItem: {
                useIt: '사용',
            },
            hotProducts: {
                seeMore: '더보기',
            },
            originality: {
                featuredCreative: '추천 크리에이티브 콘텐츠',
            },
            originalityItem: {
                likeRet: '좋아요',
            },
            productItem: {
                buyNow: '바로 구매',
            },
            series: {
                viewThisSeries: '구매하기',
            },
        },
        order: {
            afterSaleProgress: {
                details: '세부정보',
                Refunding: '환불 중',
                RefundFail: '환불 실패',
                ReplaceGoodsSucc: '교환 완료',
                ReplaceGoodsFail: '교환 실패',
                ReplaceGoodsing: '교환 중',
                RequestAfterSale: '심사 제출',
                WaitReview: '심사 대기 중',
                ReviewResolve: '심사 통과',
                ReviewReject: '심사 거절',
                WaitRefund: '환불대기 중',
                RefundSucc: '환불완료',
                WaitShip: '배송대기 중',
                WaitReceiveGoods: '배송 중',
                Completed: '배송 완료',
                UserCancel: '사용자 취소',
                refundSuccTxt: 'iCOOL 플랫폼에서 환불이 처리되었습니다. 7영업일 이내에 입금될 것으로 예상됩니다.',
            },
            goodsInfo: {
                productPrice: '제품 가격',
                freeTime1: '제한시간',
                freeTime2: '무료체험',
                balance: '남은 잔액',
                discount: '할인',
                invoice: '송장',
                invoiceInfo: '전자 청구서는 현재 지원되지 않습니다.',
                totalPrice: '합계 금액',
                orderTime: '주문 시간',
                orderNo: '주문 번호',
                paymentMethod: '결제 수단',
                couponCode: '쿠폰 코드',
                shipmentNum: '배송번호',
                copySuccess: '복사 성공',
                payType1: '잔액+바로 결제',
                payType1_1: '잔액+카드 결제',
                payType1_2: '잔액 + 카카오페이',
                payType1_3: '잔액 + 네이버페이',
                payType1_5: '잔액 + Payco',
                payType1_6: '잔액 + Toss',
                payType2: '바로 결제',
                payType3: '할인코드로 결제',
                payType4: '잔액으로 결제',
                payType2_1: '카드 결제',
                payType2_2: '카카오페이',
                payType2_3: '네이버페이',
                payType2_5: 'Payco',
                payType2_6: 'Toss',
                payType5: '할인코드 + 잔액지급',
                payType6: '할인코드 + 잔액 + 타사 결제',
                payType6_1: '할인코드 + 잔액 + 카드결제',
                payType6_2: '할인코드 + 잔액 + KakaoPay',
                payType6_3: '할인코드 + 잔액 + NaverPay',
                payType6_5: '할인코드 + 잔액 + Payco',
                payType6_6: '할인코드 + 잔액 + Toss',
                payType7: '할인코드 + 타사 결제',
                payType7_1: '할인코드 + 카드결제',
                payType7_2: '할인코드 + KakaoPay',
                payType7_3: '할인코드 + NaverPay',
                payType7_5: '할인코드 + Payco',
                payType7_6: '할인코드 + Toss',
                freight: '배송비',
            },
        },
        plays: {
            c: {
                material: {
                    background: {
                        stencil: '원판(스텐실)',
                        background: '배경',
                    },
                    collectionList: {
                        emptyInfo: '현재 관련 자료가 없습니다.',
                    },
                    hasThirdCategory: {
                        back: '뒤로가기',
                    },
                    index: {
                        library: '재료 모음',
                        officialSelection: '선택',
                        myCollection: '내 컬렉션',
                        myUploads: '나의 업로드',
                    },
                    offerList: {
                        back: '뒤로가기',
                    },
                    offerListItem: {
                        emptyInfo: '현재 관련 자료가 없습니다.',
                    },
                    uploadList: {
                        emptyInfo: '현재 관련 자료가 없습니다.',
                        uploadImage: '이미지 업로드',
                        delMaterialTitle: '삭제된 자료 목록',
                        delMaterialContent: '이 사진을 삭제 하시겠습니까?',
                    },
                    noMore: {
                        noData: '아직 관련 컨텐츠가 없습니다.',
                    },
                },
                libraryFontBox: {
                    font: '폰트',
                    text: '텍스트',
                    generate: '생성',
                    play7TxtInfo: '문자만 허용됨',
                },
                loadImg: {
                    generationStep1: '이미지 / 텍스트 분석 중',
                    generationStep2: 'AI 파라미터 매칭 중',
                    generationStep3: '색상 채우는 중',
                },
                useCutout: {
                    cutout: '자르기',
                },
                usePattern: {
                    pattern: '패턴',
                    image: '이미지',
                    copies: '복사',
                    type: '유형',
                    repeat: '반복',
                    lattice1: '격자무늬1',
                    lattice2: '격자무늬2',
                },
                useStyle: {
                    style: '스타일',
                },
                useTxtInput: {
                    useText: '텍스트 사용하기',
                    text: '텍스트',
                    generateResults: '결과 생성',
                    firstGenerate: '생성',
                    generationFailed: '생성 실패',
                },
                webFontBox: {
                    font: '폰트',
                    text: '텍스트',
                    color: '색상',
                    reset: '초기화',
                    confirm: '추가',
                },
                preBackground: {
                    selectTemplate: '템플릿을 선택하세요',
                    next: '다음',
                },
            },
            lens: {
                selectTemplate: '템플릿을 선택하세요',
                next: '다음',
            },
            config: {
                componentsConfig: {
                    play31Placeholder: '장미, 튤립, 데이지, 해바라기, 로투스, 오키드',
                    play31ConfirmText: '다음',
                    play34Placeholder: '아이스크림, 선글라스, 모자, 슬리퍼, 주스',
                    play34ConfirmText: '다음',
                    play32Placeholder: '망고, 캔디, 바닐라, 딸기',
                    play32ConfirmText: '다음',
                    play43Placeholder: '무언가를 입력하세요...(단어 3개만)',
                },
            },
            fabric: {
                base: {
                    graffiti: {
                        brush: '브러시',
                        graffiti: '그래피티',
                        size: '크기',
                        eraser: '지우개',
                    },
                    cake: {
                        flavor: '향',
                        placeholder: '망고, 캔디, 바닐라, 딸기',
                        chooseFlavor: '초기화',
                        next: '다음',
                        back: '뒤로가기',
                        text: '텍스트',
                        confirm: '추가',
                    },
                    lens: {
                        confirm: '추가',
                    },
                    layerControl: {
                        forward: '앞',
                        backward: '뒤',
                        horizontal: '수평',
                        vertical: '세로',
                        undo: '실행 취소',
                        redo: '다시 실행',
                        delete: '삭제',
                        revert: '되돌리기',
                    },
                    layerControlLeft: {
                        layers: '레이어',
                    },
                    text: {
                        textFontPopup: {
                            strokeTab: '스트로크',
                            none: '없음',
                            black: '검정',
                            white: '흰색',
                            green: '녹색',
                            purple: '보라색',
                            alpha: '알파',
                            width: '두께',
                        },
                    },
                },
            },
            cutPhoneCase: {
                quit: '그만두기 / 종료하기',
                myCase: '나의 케이스',
                font: '폰트',
                case: '핸드폰 케이스',
                style: '스타일',
                library: '재료 모음',
                cutout: '자르기',
                pattern: '패턴',
                useText: '텍스트 사용하기',
                stencil: '원판(스텐실)',
                background: '배경',
                next: '다음',
                play22Placeholder: '내용 입력...({0}단어 이하)',
                uploadImage: '이미지 업로드',
                uploadImageInfo: '* 사진을 업로드해 주세요.',
                uploadImageInfo1: '* 선명한 사진을 업로드해 주세요.',
                uploadImageInfoAccept: '* Jpeg,png사진으로 업로드 부탁드려요.',
                upload: '업로드',
                noImgTipTitle: '안내',
                noImgTipInfo: '품질을 보장하려면 이미지를 작업 영역에 배치하십시오.',
                noImgTipConfirm: '알겠어요',
                playsError: '아직 지원되지 않는 서비스입니다.',
                play42Info: '* 2장의 사진이 앞,뒤 겹쳐진 이미지로 적용됩니다.',
                play42Info1: '(예시 이미지 참고)',
                play48Info: '* 두 장의 이미지를 업로드해주세요. 상업용 이미지는 허용되지 않습니다.',
                play48Info1: '이미지는 1MB 이하로 제한됩니다.',
                play48ImgTip1: '선호하는 스타일',
                play48ImgTip2: '변화시키고 싶은 스타일',
            },
            drawPhoneCase: {
                history: '히스토리',
                generationFailed: '생성 실패',
                quit: '그만두기 / 종료하기',
                myCase: '나의 케이스',
                selectCase: '쉘 선택',
                font: '폰트',
                upload: '업로드',
                style: '스타일',
                library: '재료 모음',
                cutout: '자르기',
                pattern: '패턴',
                useText: '텍스트 사용하기',
                stencil: '원판(스텐실)',
                background: '배경',
                graffiti: '그래피티',
                reset: '초기화',
                reupload: '업로드하기',
                save: '저장',
                cutTipTitle: '자르기',
                cutTipInfo: '자르기 효과를 유지하려면 가장자리가 선명하고 본체가 있는 이미지를 선택하세요.',
                noTip: '더 이상 메시지가 표시되지 않습니다.',
                noImgTipTitle: '안내',
                quitTipInfo: '정말로 닫으시겠어요? 끄면 현재 작업이 저장되지 않습니다.',
                cake: '케이크',
                lens: '어안렌즈',
            },
            mbti: {
                temTitle: '*좋아하는 템플릿 스타일을 선택하세요.',
                title: '*MBTI 역할을 선택해 주세요.',
                next: '다음',
            },
            cake: {
                title: '*좋아하는 케이크 맛을 선택하세요.',
                placeholder: '망고, 캔디, 바닐라, 딸기',
                chooseFlavor: '맛 선택',
                next: '다음',
            },
            selectAiStyle: {
                chooseStyle: '스타일 선택',
                useIt: '사용',
            },
            selectHisimg: {
                history: '히스토리',
                hisEmptyInfo: '현재 기록 없음',
                collectRet: '모음 성공',
                collectCancel: '즐겨찾기 취소',
            },
            selectPhone: {
                phoneCase: '핸드폰 케이스',
                filter: '필터',
                confirm: '확인',
                selectModel: '모델 선택',
                next: '다음',
            },
        },
        following: {
            fans: '팔로워',
            following: '팔로잉',
            follow: '팔로우',
            followers: '팔로워',
            noOneFans: '아직 아무도 나를 팔로우하지 않습니다.',
            noOneOtherFans: '아직 Ta를 팔로우하는 사람이 없습니다.',
            noOneFollowers: '아직 팔로워가 없습니다.',
            noOneOtherFollowers: '아직 팔로워가 없습니다.',
            loading: '이미지 로드중',
            dontFollow: '더 이상 관심 없음?',
            noOneOther: '아무도 팔로우 하지 않았습니다.',
            unFollowConfirm: '팔로우를 취소 하시겠습니까?',
            reminders: '알림',
            confirm: '확인',
            cancel: '취소',
            followSuccess: '팔로우 성공',
        },
    },
    pages: {
        artists: {
            index: {
                loading: '이미지 로드중',
                title: '추천 크리에이티브 콘텐츠',
                all: '모두',
            },
            originality: {
                follow: '팔로우',
                unFollow: '언팔로우',
                storyTitle: '크리에이티브 스토리',
                searchTotal: '총 {0}개의 관련 상품',
                artist: '아티스트',
            },
        },
        author: {
            index: {
                following: '팔로잉',
                fans: '팔로워',
                follow: '팔로우',
                unFollow: '언팔로우',
                loading: '이미지 로드중',
                searchTotal: '총 {0}개의 작품',
                artwork: '작품',
                product: '상품',
                searchGoodsTotal: '총 {0}개의 관련 상품',
            },
        },
        featuredCreative: {
            seeMore: '더보기',
            noMore: '더 이상 없습니다.',
            loading: '이미지 로드중',
        },
        goods: {
            productExpired: '해당 제품 품절',
            shop: '스토어',
            details: '상세정보',
            review: '리뷰',
            synthesize: '종합',
            newest: '최신',
        },
        person: {
            applyAfterSale: {
                applyAfterSales: 'CS 신청중',
                submitAfterSales: 'CS 작성',
                afterSalesExchange: '교환',
                justRefund: '환불 요청',
                returnRefund: '반품 환불',
                afterSalesReasons: 'CS 사유',
                placeInput: '내용을 입력하세요.',
                submit: '최근 보기',
                submitSuccess: '제출 성공',
                returnPolicyTips: '이 플랫폼은 맞춤형 제품이므로 비상품 문제에 대한 환불이 지원되지 않습니다. 클릭하여 확인하세요.',
                returnPolicyBtn: '반품 정책',
                exchangeReturnPolicy: '교환/반품 정책',
                timeoutNotShipped: '시간 초과 배송되지 않음',
                timeoutNotReceived: '시간 초과 수령되지 않음',
                receivedGoodsDoNotMatch: '받은 상품이 설명과 일치하지 않거나, 손상되었거나 결함이 있는 상품',
            },
            artWork: {
                totalArtwork: '총 {0}개의 작품.',
                noArtwork: '작품이 없습니다.',
            },
            browseHistory: {
                manage: '관리',
                cancel: '취소',
                selectAll: '전체 선택',
                goods: '상품',
                expired: '만료됨',
                noHistory: '최근 본 상품이 없습니다.',
                creative: '크리에이티브',
                selectDelHistoryTip: '삭제할 기록을 선택하세요.',
                deleteHistoryConfirm: '총 {0}개를 기록에서 삭제하시겠습니까?',
                deleteReminders: '알림 끄기',
                confirm: '확인',
                today: '오늘',
                deleteSuccess: '삭제 완료',
            },
            index: {
                man: '남성',
                woman: '여성',
                signature: '설명',
                hobby: '요즘 관심있는 컨텐츠를 선택해 주세요  (ex. 만화, 웹툰, 연예인 등)',
                confirm: '확인',
                username: '이름',
                operationSuccess: '작업 완료',
            },
            like: {
                goods: '상품',
                likeGoodsTotalTip: '총 {0}개의 좋아요 표시한 상품',
                expired: '만료됨',
                emptyLike: '현재 좋아하는 항목이 없습니다',
                creative: '크리에이티브',
                likeCreativeTotalTip: '총 {0}개의 좋아요 표시한 크리에이티브 콘텐츠',
                canceledSuccessfully: '취소 성공',
                buyNow: '바로 구매',
                creativeExpired: '크리에이티브 콘텐츠가 만료되었습니다.',
            },
            notice: {
                notice: '주문/배송 정보',
                view: '보기',
                emptyMsg: '아직 배송 정보가 없습니다.',
                packageInfo: '패키지 정보',
                refundInfoDynamics: '패키지 현황',
                packageDynamics: '패키지 동향',
                orderNormalTitle: '주문 배송 정보',
                orderRefundTitle: '주문 환불 및 교환 정보',
                refundTitleSuccess: '귀하의  환불 주문건이  정상적으로 처리되었습니다. ',
                refundTitleFaild: '귀하의 환불 주문건이  취소되었습니다.',
                bvy: '구매',
                replace: '교환  ',
                logistics_level_msg_title1: '{0} 상품이 출고되었습니다',
                logistics_level_msg_title3: '{0} 상품이 배송 중입니다',
                logistics_level_msg_title6: '{0} 상품 배송이 완료 되었습니다',
                refundInfoSuccess: '환불 처리되었습니다.',
                refundInfoFaild: '환불 신청에 실패하였습니다. 다시 시도해 주십시오',
            },
            orderDetail: {
                orderStatus: {
                    1: '결제 대기',
                    2: '배송준비',
                    3: '배송중',
                    5: '구매확정',
                    6: '취소됨',
                    7: '교환/반품',
                },
                edit: '편집',
                deleteOrder: '주문 삭제',
                payment: '결제',
                viewLogistics: '택배 조회',
                confirmReceipt: '구매 확정',
                applyAfterSales: 'CS 신청중',
                deleteReminderContent: '이 주문을 삭제 하시겠습니까?',
                deleteReminderTitle: '알림 삭제',
                confirm: '확인',
                cancel: '취소',
                confirmReceiptSuccess: '제품을 수령했습니다.',
                confirmReceiptInfo: '제품을 받으셨습니까?',
                delSuccess: '삭제 완료',
                afterSaleEventStatus: {
                    Refunding: '환불 중',
                    RefundFail: '환불 실패',
                    ReplaceGoodsSucc: '교환 완료',
                    ReplaceGoodsFail: '교환 실패',
                    ReplaceGoodsing: '교환 중',
                    RequestAfterSale: '심사 제출',
                    WaitReview: '심사 대기 중',
                    ReviewResolve: '심사 통과',
                    ReviewReject: '심사 거절',
                    WaitRefund: '환불대기 중',
                    RefundSucc: '환불완료',
                    WaitShip: '배송대기 중',
                    WaitReceiveGoods: '배송 중',
                    Completed: '배송 완료',
                    UserCancel: '사용자 취소',
                },
            },
            orderLogistics: {
                logistics_1: '상품 확인',
                logistics_2: '상품 이동중',
                logistics_3: '배송지 도착',
                logistics_4: '배송 출발',
                logistics_5: '배송 완료',
                logisticsDetails: '상품 배송 세부정보',
            },
            order: {
                all: '전체',
                orderStatus: {
                    1: '결제 대기',
                    2: '배송준비',
                    3: '배송중',
                    4: '리뷰 작성',
                    5: '구매확정',
                    6: '취소됨',
                    7: '교환/반품',
                    8: '배송준비중',
                },
                afterSaleStatus: {
                    '-1': '사용자가 취소합니다',
                    1: '검토 대기 중',
                    2: '배송준비중',
                    3: '배송중',
                    4: '배송완료',
                    5: '감사 거부',
                    6: '환불 대기중',
                    7: '환불 중',
                },
                deleteOrder: '주문 삭제',
                actuallyPaid: '결제 금액: ',
                orderDetail: '주문 세부정보',
                viewLogistics: '택배 조회',
                confirmReceipt: '구매 확정',
                applyAfterSales: 'CS 신청중',
                afterSalesProgress: 'CS 진행 내역',
                payment: '결제',
                orderNoMoreinfo: '주문 내역 없음',
                deleteReminderTitle: '알림 삭제',
                deleteReminderContent: '이 주문을 삭제 하시겠습니까?',
                confirm: '확인',
                cancel: '취소',
                delSuccess: '삭제 완료',
                confirmReceiptInfo: '제품을 받으셨습니까?',
                confirmReceiptSuccess: '제품을 수령했습니다.',
            },
            review: {
                rule: '주문한 제품을 수령하신 후 리뷰를 작성 할 수 있습니다.',
                beEvaluated: '리뷰 작성',
                totalBeReviewTxt: '총 {0}개의 리뷰가 있습니다',
                evaluate: '리뷰',
                noBeReview: '리뷰 가능한 상품이 없습니다.',
                totalReviewTxt: '총 {0}개의 리뷰',
                evaluated: '작성한 리뷰',
                pending: '검토중',
                rejected: '리뷰 내용이 현재 검토 중입니다.',
                ealuationYet: '아직 작성된 리뷰가 없습니다.',
                evaluationRules: '평가 규칙',
                evaluationRulesT1: '1. 상세 주문 내역',
                evaluationRulesC1: '1. 주문 완료 후 60일 이내, 구매한 제품에 대한 리뷰를 작성할 수 있습니다.',
                evaluationRulesT2: '2. 주문 평가 지침.',
                evaluationRulesC2_1: `1. 평가의 객관성 보장을 위해 평가 내용이 게재된 후에는 수정 또는 삭제가 불가 합니다.`,
                evaluationRulesC2_2: `2. 동일한 상품을 여러 개 구입할 경우, 하나의 리뷰만 작성 할 수 있습니다.`,
                evaluationRulesC2_3: `3. 리뷰 작성 중 별도 링크 삽입은 불가능 합니다. 링크게 기제된 리뷰는 제출 되지 않습니다.`,
                evaluationRulesC2_4: `4. 차단된 사용자는 차단 기간 동안 리뷰를 작성할 수 없으며, 고객센터로 문의하여 상담하실 수 있습니다.`,
                evaluationRulesC2_5: `5. 작성이 완료된 리뷰는 수정 및 삭제가 불가능합니다. 
  이에, 리뷰 작성시 본인이나 타인의 민감한 개인정보가 노출되지 않도록 주의해 주시기 바랍니다.`,
                evaluationRulesC2_6: `6. 개인 정보 보호를 위해 리뷰 내역을 숨기려면, 주문 목록으로 이동하여 해당 주문을 숨김 처리 해야만 가능합니다. 숨김 처리된 리뷰는 추후 복원되지 않습니다.`,
                evaluationRulesC2_7: `7. "반품 및 교환 고객 리뷰 작성 (제품 반품 전 리뷰를 작성하지 않은 경우) : 제품 반품 신청 후 리뷰 작성은 불가능 하며, 반품이 완료되기 전(제품 반품 신청 전 작성된 리뷰에 한해) 리뷰가 완성된 경우에는 제품 반품 완료 후 해당 리뷰가 정상적으로 표시됩니다. 반품 및 교환 주문 상품 평가 : (제품 교환 전 리뷰를 작성하지 않은 경우) 교환 전 상품 리뷰는 제품 교환 완료 후 작성할 수 있습니다. : 주문 상품은 상품 교환 전, 후 리뷰 작성이 모두 가능합니다. 다만, 제품 교환 후 신규 리뷰를 작성하실 경우 교환 전 작성된 리뷰는 노출되지 않으며 교환 후 작성하신 리뷰만 노출 됩니다. "`,
                evaluationRulesC2_8: `8. 홈페이지 또는 APP 상에 게시된 상품 리뷰와 댓글은 SNS 채널, 포털 사이트 등에 
  전체 또는 요약 형태로 노출 될 수 있습니다. `,
                evaluationRulesC2_9: `9. 개인 정보 사용 : 사용자 개인 정보 보호를 위해 제품 세부 정보 페이지에 게시된 리뷰는 
  익명으로 표시 됩니다. `,
            },
            writeReview: {
                evaluate: '평가',
                productScore: '상품 평가',
                reviewContent: '리뷰 내용',
                placeholderTxt: '평가 내용 작성  ',
                remarkTxt: '평가 내용은 상품 평가 페이지에서 익명으로 노출 됩니다.',
                submit: '최근 보기',
                star1: '매우 나쁨',
                star2: '나쁨',
                star3: '보통',
                star4: '좋다',
                star5: '아주 좋다',
                evaluationSuccess: '리뷰 작성을 완료 하였습니다.',
            },
            coupons: {
                coupons: '보유 쿠폰',
                off: '할인',
                validityPeriod: '유효기간 :',
                emptyMsg: '현재 사용 가능한 쿠폰이 없습니다.',
                couponCode: '쿠폰 코드 :',
            },
        },
        setup: {
            account: {
                account: '계정',
                tel: '휴대폰 번호',
                noSetting: '설정되지 않음',
                modify: '수정',
                bindingInfo: '연결 정보',
                email: '이메일',
                setting: '설정',
                initSettingPwd: '먼저 이메일 주소 또는 휴대전화 번호를 입력하세요',
                modifyPwd: {
                    pwd: '비밀번호',
                },
            },
            address: {
                myAddress: '배송정보',
                receiver: '이름',
                name: '이름',
                tel: '전화번호',
                area: '배송 주소',
                address: '상세 주소',
                enterAddress: '상세 주소 입력',
                defaultAddress: '기본 배송지로 설정',
                cancel: '취소',
                confirm: '변경사항 확인',
                save: '저장',
                addAddress: '주소 추가',
                delete: '삭제',
                edit: '편집',
                addSuccess: '추가 완료',
                modifySuccess: '수정 완료',
                operationSuccess: '작업 완료',
                addressDelTip: '주소를 삭제하시겠습니까?',
                reminders: '알림',
                confirmAddr: '확인',
                cancelAddr: '취소',
                deleteSuccess: '삭제 완료',
            },
            bindingEmail: {
                emailBinding: '이메일 인증',
                telAuthentication: '번호 인증',
                getVerificationCode: '인증번호 확인',
                verificationCode: '인증번호',
                email: '이메일',
                confirm: '확인',
                sendSuccess: '전송 완료',
                operationSuccess: '작업 완료',
                emailErrTip: '유효한 이메일을 입력해 주세요.',
                verificationCodeErrTip: '인증 코드를 입력하십시오',
            },
            bindingInformation: {
                bindingInfo: '연결 정보',
            },
            bindingPhone: {
                emailAuthentication: '이메일 인증',
                telBinding: '번호 인증',
                verificationCode: '인증번호',
                getVerificationCode: '인증번호 확인',
                tel: '휴대폰 번호',
                confirm: '확인',
                sendSuccess: '전송 완료',
                operationSuccess: '작업 완료',
                telErrTip: '휴대폰 번호를 정확하게 입력해 주세요.',
                verificationCodeErrTip: '인증 코드를 입력하십시오',
            },
            modifyEmail: {
                modifyEmail: '이메일 수정',
                emailAuthentication: '이메일 인증',
                telAuthentication: '번호 인증',
                verificationCode: '인증번호',
                getVerificationCode: '인증번호 확인',
                email: '이메일',
                confirm: '확인',
                sendSuccess: '전송 완료',
                operationSuccess: '작업 완료',
                emailErrTip: '유효한 이메일을 입력해 주세요.',
                verificationCodeErrTip: '인증 코드를 입력하십시오',
            },
            modifyPhone: {
                modifyTel: '휴대폰 번호 수정',
                emailAuthentication: '이메일 인증',
                telAuthentication: '번호 인증',
                verificationCode: '인증번호',
                getVerificationCode: '인증번호 확인',
                newTel: '새 휴대폰 번호',
                confirm: '확인',
                sendSuccess: '전송 완료',
                operationSuccess: '작업 완료',
                telErrTip: '휴대폰 번호를 정확하게 입력해 주세요.',
                verificationCodeErrTip: '인증 코드를 입력하십시오',
            },
            modifyPwd: {
                modifyPwd: '비밀번호 수정',
                emailAuthentication: '이메일 인증',
                telAuthentication: '번호 인증',
                confirmPwd: '비밀번호 확인',
                verificationCode: '인증번호',
                getVerificationCode: '인증번호 확인',
                pwd: '비밀번호',
                confirm: '확인',
                pwdErrTip: '비밀번호 길이는 6~20자로 제한됩니다',
                sendSuccess: '전송 완료',
                operationSuccess: '작업 완료',
                passwordsNitMatch: '비밀번호가 일치하지 않습니다',
                verificationCodeErrTip: '인증 코드를 입력하십시오',
            },
            setPwd: {
                setPwd: '비밀번호 설정',
                emailAuthentication: '이메일 인증',
                telAuthentication: '번호 인증',
                verificationCode: '인증번호',
                getVerificationCode: '인증번호 확인',
                pwd: '비밀번호',
                confirmPwd: '비밀번호 확인',
                confirm: '확인',
                pwdErrTip: '비밀번호 길이는 6~20자로 제한됩니다',
                sendSuccess: '전송 완료',
                operationSuccess: '작업 완료',
                passwordsNitMatch: '비밀번호가 일치하지 않습니다',
                verificationCodeErrTip: '인증 코드를 입력하십시오',
            },
        },
        wallet: {
            bankCard: {
                myBankCard: '나의 카드',
                card: '카드 번호',
                addBankCard: '카드 추가하기',
                unbind: '연동 해제',
                noBankCard: '현재 등록된 카드가 없습니다.',
                addSuccess: '추가 완료',
                unbundleBankCardContent: '카드 연동을 해지하시겠습니까?',
                unbundleBankCard: '카드 연동 해제',
                operationSuccess: '작업 완료',
                cancel: '취소',
                confirm: '변경사항 확인',
            },
            bill: {
                bill: '영수증',
                transactionType: '거래 유형',
                orderAmount: '주문 금액',
                details: '세부정보',
                noMore: '결제할 내역이 없습니다.',
                withdraw: '출금',
                all: '모두',
                income: '세금',
                purchase: '구매',
                refund: '환불',
                withdraw2: '결제하기',
                refund2: '환불하기',
                basefee: '기본 수수료',
                buyOrder: '주문하기',
                settlementDividendFee: '결제 수수료',
                settlementCommissionFee: '정산 수수료',
            },
            withdrawResult: {
                withdrawSuccessfully: '출금 성공',
                withdrawSuccessfullyContent: '출금 신청이 검토 중입니다. 잠시 기다려 주십시오',
                goBack: '뒤로 가기',
                details: '세부정보',
            },
            withdraw: {
                withdraw: '출금',
                bankCard: '카드 번호',
                addBankCard: '카드 추가하기',
                withdrawalAmount: '출금 금액',
                minimumWithdrawal: '최소 출금 한도',
                availableBalance: '사용 가능한 잔액',
                all: '모두',
                confirm: '확인',
                tel: '휴대폰 번호',
                email: '이메일',
                verificationCode: '인증번호',
                getVerificationCode: '인증번호 확인',
                emailAuthentication: '이메일 인증',
                telAuthentication: '번호 인증',
                addSuccess: '추가 성공',
                withdreawalTips: '출금 안내',
                withdreawalTipsConfirm: '연동하기',
                withdreawalTipsContent: '계정 보안을 위해 스마트폰 번호 또는 이메일 주소를 연동하고 확인 후 출금하시기 바랍니다',
                cancel: '취소',
            },
        },
        404: {
            notFound: '요청하신 페이지를 찾을 수 없습니다. 다시 시도해 주세요.',
            back: '뒤로가기',
        },
        cart: {
            cart: '장바구니',
            selectAll: '전체 선택',
            delete: '삭제',
            orderSummary: '주문 요약',
            expired: '만료됨',
            number: '수량',
            totalPrice: '상품총액',
            discount: '할인',
            settleOrder: '주문 결제',
            cartEmptyTip: '장바구니가 비어 있습니다. 마음에 드는 상품을 추가하세요.',
            shop: '스토어',
            delSku: '이 {0} 제품을 삭제하시겠습니까?',
            confirm: '확인',
            cancel: '취소',
            delSuccess: '삭제 완료',
            deleteReminders: '알림 끄기',
        },
        createOrder: {
            confirmOrder: '주문 확인',
            orderAddress: '배송지',
            addAddress: '배송지 추가',
            default: '기본 설정',
            orderGoods: '상품 상세 정보',
            orderInfo: '주문 상세 정보',
            orderNotesTip: '여기에 주문 메모를 작성하세요.',
            orderNotes: '주문 메모',
            expired: '만료됨',
            orderSummary: '주문 요약',
            number: '수량',
            totalPrice: '총 주문금액',
            freight: '배송비',
            freeTime1: '제한시간',
            freeTime2: '무료체험',
            discount: '할인',
            enterPromoCode: '할인코드 입력',
            validate: '인증',
            availableBalance: '사용 가능한 잔액',
            deduction: '할인',
            payment: '결제',
            back: '뒤로가기',
            edit: '편집',
            redemptionSuccessful: '인증 성공',
            invalidPromoCodeInfo: '해당 할인 코드는 사용할 수 없습니다. 확인 후 다시 시도해 주세요.',
        },
        customize: {
            title: '커스텀',
            loading: '이미지 로드중',
            all: '모두',
        },
        login: {
            telLogin: '휴대폰 번호로 로그인',
            emailLogin: '이메일 로그인',
            email: '이메일',
            verificationCode: '인증번호',
            pwd: '비밀번호',
            send: '보내기',
            pwdLogin: '비밀번호 로그인',
            verificationCodeLogin: '인증번호 로그인',
            tel: '휴대폰 번호',
            otherLogin: '기타 로그인 방법',
            login: '로그인',
            emailErrTip: '유효한 이메일을 입력해 주세요.',
            telErrTip: '휴대폰 번호를 정확하게 입력해 주세요.',
            pwdErrTip: '비밀번호 길이는 6~20자로 제한됩니다',
            sendSuccess: '전송 완료',
            verificationCodeErrTip: '인증 코드를 입력하십시오',
            signUp: '가입하기',
            phoneNumberVerification: '휴대폰 번호로 가입',
            emailVerification: '이메일로 가입',
            confirmPwd: '비밀번호 확인',
            passwordsNitMatch: '비밀번호가 일치하지 않습니다',
            userPrivacyAlerts: '사용자 개인정보 경고',
            agreement1: `로그인함으로써 귀하는 당사의`,
            agreement2: ` “개인정보 처리방침”`,
            agreement3: ` 및`,
            agreement4: ` “서비스 이용 약관”`,
            dialogAgreement: '다음을 읽고 동의해 주십시오',
            dialogAgree: '동의하고 계속하기',
            userAgreement: '서비스 이용 약관',
            privacyAgreement: '개인정보 처리방침',
        },
        payResult: {
            paySuccess: '결제 성공',
            payFail: '결제 실패',
            paySuccessInfo1: '제품을 제작중에 있습니다. 빠른 시일안에 배송 될 예정입니다. ',
            shop: '스토어',
            viewOrder: '주문 보기',
            insTips1: '제품 구매 후, iCOOL 인스타그램(',
            insTips2: 'icool.kr',
            insTips3: ') 프로필 링크 내 폼 작성을 완료해야 추첨 대상에 포함됩니다.',
            insBtn: 'Go Now',
        },
        // pages/person.vue
        personSingle: {
            following: '팔로우',
            isArtist: '아티스트',
            fans: '팔로워',
            myWallet: '나의 지갑',
            myOrder: '주문 내역',
            artWorks: '나의 작품',
            like: '좋아요',
            browserRecord: '최근 본 상품',
            myComments: '나의 리뷰',
            notice: '주문/배송 정보',
            setUp: '설정',
            coupons: '쿠폰',
        },
        series: {
            follow: '팔로우',
            unFollow: '언팔로우',
            coCreativeDesigner: '협업 디자이너',
            title: '시리즈',
            searchTotal: '총 {0}개의 관련 상품',
        },
        //pages/setup.vue
        setupSingle: {
            setUp: '설정',
            logOut: '로그아웃',
            account: '계정',
            myAddress: '배송지',
            bindingInformation: '연결 정보',
            contactUs: '문의하기',
            userAgreement: '이용 약관',
            serviceAgreement: '서비스 약관',
            privacyAgreement: '개인정보 보호 정책',
            exchangeReturnPolicy: '반품/교환 정책',
            faq: '자주 묻는 질문과 답변',
            deliveryPolicy: '배송 정책',
            logoutConfirm: '로그아웃 하시겠습니까?',
            reminders: '알림',
            confirm: '확인',
            cancel: '취소',
        },
        shop: {
            shop: '스토어',
            search: '검색',
            filters: '필터',
            fold: '축소',
            unFold: '확장',
            loading: '이미지 로드중',
            noSearches: '해당 상품을 찾을 수 없습니다.',
            series: '시리즈',
            artist: '아티스트',
        },
        wallte: {
            myWallet: '나의 지갑',
            walletBalance: '지갑 잔액',
            bill: '영수증',
            bankCard: '카드 ({0})',
        },
    },
    hooks: {
        useCollectPost: {
            likeSuccess: '추가 되었습니다. 마이 페이지- 찜한 상품에서 확인하세요.',
            likeCancel: '좋아요 취소',
        },
        useCollectProduct: {
            likeSuccess: '추가 되었습니다. 마이 페이지- 찜한 상품에서 확인하세요.',
            likeCancel: '좋아요 취소',
        },
        useFollow: {
            unFollowConfirm: '팔로우를 취소 하시겠습니까?',
            reminders: '알림',
            confirm: '확인',
            likeCancel: '좋아요 취소',
            followSuccess: '필로우 성공',
            cancel: '취소',
        },
    },
    utils: {
        estimateTime: {
            arrivalTimeValue1: '오늘({0})내 도착 예상',
            arrivalTimeValue2: '내일({0})내 도착 예상',
            arrivalTimeValue3: '모레({0}) 내 도착 예상',
            arrivalTimeValue4: '{0}전 예상',
            arrivalTimeValue5: '{0}예상',
        },
        http: {
            serveError: '네트워크 오류, 나중에 다시 시도하십시오.',
        },
        index: {
            hoursAgo: '{0} 시간 전에',
            minAgo: '{0} 분 전',
            secondsAgo: '{0} 초 전',
            emailErrTip: '유효한 이메일을 입력해주세요',
            pwdErrTip: '비밀번호 길이는 6~20자로 제한됩니다',
            yesterday: '어제',
            sunday: '일요일',
            monday: '월요일',
            tuesday: '화요일',
            wednesday: '수요일',
            thursday: '목요일',
            friday: '금요일',
            saturday: '토요일',
            telErrTip: '휴대폰 번호를 정확하게 입력해 주세요.',
        },
        skuUtils: {
            likeSuccess: '추가 되었습니다. 마이 페이지- 찜한 상품에서 확인하세요',
            likeCancel: '취소 완료',
            networkError: '네트워크 문제가 발생하였습니다. 잠시 후 다시 시도하세요.',
        },
    },
};
