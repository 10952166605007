import Http from '@/utils/http'
import type { ArtistGoodData, ArtworkData } from './interface'

export const loginEmail = (params: any) => {
  return Http.post('/api/user/client/mail/login', params)
}

export const loginTel = (params: any) => {
  return Http.post('/api/user/client/tel/login', params)
}

export const loginPwd = (params: any) => {
  return Http.post('/api/user/client/pwd/login', params)
}

export const regEmail = (params: any) => {
  return Http.post('/api/user/client/register/email', params)
}

export const regTel = (params: any) => {
  return Http.post('/api/user/client/register/tel', params)
}

export const ThirdLoginType = {
  FACEBOOK: 3,
  GOOGLE: 4,
  APPLE: 5,
  KAKAO: 6,
  NAVER: 7,
}
//sourceId  facebook:3, google: 4,APPLE: 5, kakaoTalk:6, naver: 7
//accessToken
//inviteCode
export const loginThird = (params: any) => {
  return Http.post('/api/user/client/third/login', params)
}

export const loginTourist = (params: any) => {
  return Http.post('/api/user/client/tourist/login', params)
}

export const VerificationType = {
  LOGIN: 'LOGIN',
  BIND_INFO: 'BIND_INFO',
  CHANGE_INFO: 'CHANGE_INFO',
  APPLY_WITHDRAW: 'APPLY_WITHDRAW',
}

export const VerificationOwnType = {
  TEL: 'TEL',
  EMAIL: 'EMAIL',
}

export const sendEmailCode = (params: any) => {
  return Http.post('/api/user/client/send/email/verification', params)
}

//verificationType LOGIN:登录，BIND_INFO: 绑定信息，CHANGE_INFO: 更改信息
export const sendTelCode = (params: any) => {
  return Http.post('/api/user/client/send/tel/verification', params)
}

export const sendOwnCode = (params: any) => {
  return Http.post('/api/user/client/send/own/verification', params)
}

export const modifyInfo = (params: any) => {
  return Http.post('/api/user/client/modify/info', params)
}

export const getAccountInfo = () => {
  return Http.get('/api/user/client/account/info')
}

export const getHomePage = (params: any) => {
  return Http.get('/api/user/client/homepage', params)
}

export const getOwnerInfo = () => {
  return Http.get('/api/user/client/homepage/owner')
}

export const bindThirdPart = (params: any) => {
  return Http.post('/api/user/client/bind/third/part', params)
}

export const setContact = (params: any) => {
  return Http.post('/api/user/client/set/contact', params)
}

export const modifyContact = (params: any) => {
  return Http.post('/api/user/client/modify/contact', params)
}

export const setLoginPwd = (params: any) => {
  return Http.post('/api/user/client/set/loginpwd', params)
}

export const logout = () => {
  return Http.post('/api/user/client/logout')
}

export const clientDel = () => {
  return Http.post('/api/user/client/del')
}

// 获取推荐列表
export const useApiUserGetRecommednList = (params: any) => {
  return Http.get('/api/user/client/recommend/list', params)
}

// 关注
export const useApiUserFollowUser = (params: { targetUserId: string }) => {
  return Http.post('/api/user/client/follow', params)
}

// 取关
export const useApiUserUnFollowUser = (params: { targetUserId: string }) => {
  return Http.post('/api/user/client/follow/cancel', params)
}

// 根据用户名查找
export const useApiUserSearchUser = (params: { nickname: string }) => {
  return Http.get('/api/user/client/search/user', params)
}

// 朋友列表
export const getUserFriend = () => {
  return Http.get('/api/user/client/user/friend', { page: 1, limit: 100 })
}

// 获取用户封禁原因
export const useApiUserGetDisableInfo = (params: { token: string }) => {
  return Http.get('/api/user/client/disable/info', params)
}

// 进行用户封禁申诉
export const useApiUserDisableAppeal = (params: { token: string; reason: string; imgs: string }) => {
  return Http.post('/api/user/client/submit/appeal', params)
}

//创建收款人
export const useApiCreateBeneficiary = (params: any) => {
  return Http.post('/api/pay/client/beneficiary/create', params)
}

//解绑收款人
export const useApiDelBeneficiary = (beneficiaryId: any) => {
  return Http.post('/api/pay/client/beneficiary/del', { beneficiaryId })
}

//银行卡列表
export const useApiCardList = () => {
  return Http.get('/api/user/client/card/list')
}

//添加地址前获取code
export const useAddCardBeneficiaryAuthCode = (codeChallenge: string) => {
  return Http.get(`/api/pay/client/embed/beneficiary/auth/code?codeVerifier=${codeChallenge}`)
}

// 完成新手引导
export const useApiUserFinishGuide = () => {
  return Http.post('/api/user/client/finish/guide')
}

// 获取用户浏览记录
export const useApiUserGetBrowseList = (params: PageLimit & { type?: number }) => {
  return Http.get('/api/behavior/client/browse', params)
}
// 删除用户浏览记录 全选的时候 browseIds传未选中的，否则就是选中的
export const useApiUserDelBrowseList = (params: { browseIds: number[]; isAll: boolean; type: number }) => {
  return Http.post('/api/behavior/client/del/shop/browse', params)
}

// 删除用户喜欢记录 全选的时候 likeIds传未选中的，否则就是选中的
export const useApiUserDelLikeList = (params: { likeIds: number[]; isAll: boolean; type: number }) => {
  return Http.post('/api/behavior/client/del/like', params)
}

// 发布评价
export const useApiUserSubmitComment = (params: { star: number; content: string; orderItemId: string; imgs: string }) => {
  return Http.post('/api/behavior/client/submit/comment', params)
}

// 获取订单商品详情
export const useApiGetOrderItemInfoById = (params: { orderItemId: string }) => {
  return Http.get('/api/order/client/order/item/detail', params)
}

// 获取待评价商品订单
export const useApiUserGetCommentList = (params: PageLimit) => {
  return Http.get('/api/order/client/order/uncomment/list', params)
}

// 获取订单商品评价详情
export const useApiGetCommentSpuInfoById = (params: { commentShopId: string }) => {
  return Http.get('/api/behavior/client/comment/spu/info', params)
}

// 获取商品评价列表
export const useApiUserGetSpuCommentList = (params: PageLimit & { spuId: string }) => {
  return Http.get('/api/behavior/client/comment/spu/list', params)
}

// 用户主页
export const getUserInfo = (userId?: string) => Http.get(`/api/user/client/homepage`, { userId })

// 提交邮件接口
export const useApiSubmitUemail = (params: { email: string }) => {
  return Http.post('/api/user/client/concat/us', params)
}

// 获取艺术家主页的关联商品
export const getSpuHome = (params: { page: number; limit: number; userId?: number }) => Http.get(`/api/prod/client/spu/homepage`, params)

//用户资产
export const useApiUserClientAssets = () => {
  return Http.get('/api/user/client/assets')
}

//钱包流水
export const useApiUserClientWalletRecord = (params: PageLimit & { currency: string; operationType: string; month: string }) => {
  return Http.get('/api/user/client/wallet/record', params)
}

//钱包详情
export const useApiUserClientWalletDetail = (params: { recordId: string }) => {
  return Http.get('/api/user/client/wallet/detail', params)
}

//申请提现
export const useApiUserClientWalletApplyWithdraw = (params: any) => {
  return Http.post('/api/user/client/wallet/apply/withdraw', params)
}

//获取最小提现金额
export const useApiUserClientWithdrawLimit = () => {
  return Http.get('/api/user/client/withdraw/limit')
}

// 获取艺术家作品列表
export const getUserWorks = (params: {
  userId?: any
  page?: number
  limit?: number
}): Promise<{
  data: { rows: ArtworkData[]; count: number }
}> => {
  return Http.post('/api/post/client/post/page', params)
}

// 获取艺术家作关联商品列表
export const getUserArtictGoods = (params: {
  useId?: number
}): Promise<{
  data: { rows: ArtistGoodData[]; count: number }
}> => {
  return Http.get('/api/post/client/ip/artist/spu/list', params)
}

// 查询活动 1 可用优惠券
export const useApiGetActivity1Coupon = () => {
  return Http.get('/api/order/client/activity/1/coupon')
}

// 查询可用优惠券
export const useApiGetActivityCoupon = () => {
  return Http.get('/api/order/client/activity/coupon')
}

// 支付券列表
export const useApiGetActivityCoupons = () => {
  return Http.get('/api/order/client/activity/coupons')
}

//关注列表
export const useApiGetUserFollow = (params: { page: number; limit: number; userId?: number }) => {
  return Http.get('/api/user/client/user/follow', params)
}

//粉丝列表
export const useApiGetUserFans = (params: { page: number; limit: number; userId?: number }) => {
  return Http.get('/api/user/client/user/fans', params)
}

// 获取艺术家作其他的关联商品列表
export const getUserOtherArtictGoods = (params: {
  useId: number
  notSpuId?: number
  limit?: number
}): Promise<{
  data: ArtistGoodData[]
}> => {
  return Http.post('/api/prod/client/spu/list', params)
}
