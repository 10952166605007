import type { RouterConfig } from '@nuxt/schema'
export default <RouterConfig>{
  scrollBehavior: async (to, from, savedPosition) => {
    const history = useHistoryStore()
    const keys = history.getHistoryKeys()
    // 如果是去往最后一个页面，则是后退操作，则清除history最后一个记录
    if (to.fullPath == keys[keys.length - 1]) {
      history.deleteItem(keys[keys.length - 1])
    } else {
      // 否则，记录当前页面的离开时间和路由
      history.addItem(from.fullPath, `${from.fullPath},${Date.now()}`)
    }
    if (savedPosition) {
      return savedPosition
    }

    const findEl = async (hash: any, x = 0) => {
      return (
        document.querySelector(hash) ||
        new Promise((resolve) => {
          if (x > 0) {
            return resolve(document.querySelector('#app'))
          }
          setTimeout(() => {
            resolve(findEl(hash, 1))
          }, 300)
        })
      )
    }

    if (to.hash) {
      const el = await findEl(to.hash)
      const top = 60
      const _top = (el.getBoundingClientRect()?.top || 0) + (window.scrollY || 0)
      if ('scrollBehavior' in document.documentElement.style) {
        return window.scrollTo({ top: _top - top, behavior: 'smooth' })
      } else {
        return window.scrollTo(0, _top)
      }
    }
    // return { left: 0, top: 0, behaviour: 'smooth' }
    // return { left: 0, top: 0 }
  },
}
