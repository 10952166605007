import Http from '@/utils/http'
import { ORDERSTATUS, AFTERSERVICE } from '@/utils/type'

// 获取手机壳属性列表 = 返回属性根据id走, 1:边框颜色2材质3机型
export const useApiOrderGetCaseAttr = (categoryId?: number) => {
  return Http.get('/api/prod/client/attribute', { categoryId })
}

// 获取全部属性
export const useApiClientGetCaseAttr = () => {
  return Http.get('/api/prod/client/attribute/category_attr_obj')
}

// COTD 获取属性和属性值列表
export const useApiClientGetAttrList = () => {
  return Http.get('/api/prod/client/attribute/all_list')
}

// COTD 根据主商品id获取组合信息
export const useApiClientGetBundlePrimarySpu = (spuId: string) => {
  return Http.get(`/api/prod/client/bundle/one/primary_spu/${spuId}`)
}

// 获取手机壳价格
export const useApiOrderGetCasePrice = () => {
  return Http.get('/api/prod/client/sku/price')
}

// 创建sku（定制直接下单时创建）
interface CreateSku {
  // imgUrl: string // 多图用逗号隔开
  // categoryId: 3 | 5 // 类目id 3=>手机 5=>灯光画
  // attrs: any
  // customParam: {
  //   // imgUrl: string
  //   // scale: string
  //   type: string // iPhone,iMac,iWatch,iPad,lightPicture
  //   // imgId: string
  //   // postId?: string
  // }
  skuId: string
  coverUrl: string
  customParam: {
    imgUrl: string // 原图地址
    scale: string // 原图尺寸
    playMethodId: string // 玩法id
  }
}
export const useApiOrderCreateSku = (params: CreateSku) => {
  return Http.post('/api/prod/client/sku/add_for_order', params)
}

// 查询sku详情
export const useApiOrderGetSkuDetail = (params: {
  addrId?: string
  skuId?: string
  prodCount?: string
  couponNo?: string
  useWalletBalance?: boolean
  isCart?: boolean
  bundleId?: string
}) => {
  return Http.post('/api/order/client/order/pre_info', params)
}

// 检查优惠券是否有效
export const useApiOrderCheckCoupon = (params: { couponNo?: string; skuId?: string }) => {
  return Http.get('/api/order/client/coupon/check', params)
}

// 检查sku是否能使用优惠券
export const useApiOrderCheckSkuUseCoupon = (params: { skuId?: string; activityId?: string }) => {
  return Http.post('/api/prod/client/sku/activity', params)
}

// 创建订单
export const useApiOrdercreateOrder = (params: {
  skuId: string
  prodCount: string
  addrId: string
  couponNo?: string
  useWalletBalance?: boolean
  isCart?: boolean
  userRemark?: string
  bundleId?: string
  backBoardIds?: Array<string>
}) => {
  return Http.post('/api/order/client/order/create', params)
}

// 支付优惠券(已移除)
export const useApiOrderPayCoupon = (params: { orderNo?: string }) => {
  return Http.post('/api/pay/client/pay/coupons', params)
}

// 修改sku属性
export const useApiProdSkuUpdateAttr = (params: any) => {
  return Http.post('/api/prod/client/sku/update/attr', params)
}

interface AddrParams {
  // stateId?: string // integer 区域id
  // cityId?: string // integer 城市id
  state?: string
  city?: string
  extraAddr?: string
  receiver?: string // string 收货人
  postCode?: string // string 邮编
  addr?: string // string 地址
  mobile?: string // string 收货手机号
  commonAddr?: 0 | 1 // integer 是否默认地址 1: 默认地址 0非默认地址
}
// 新增收货地址列表
export const useApiOrderAddUserAddrList = (params: AddrParams) => {
  return Http.post('/api/user/client/add/addr', params)
}
// 获取收货地址详细
export const useApiOrderAddrDetail = (params: { addrId: string }) => {
  return Http.get('/api/user/client/addr/info', params)
}
// 修改收货地址列表 status 1: 开启 0：删除
export const useApiOrderEditUserAddrList = (params: AddrParams & { addrId: string; status?: 0 | 1 }) => {
  return Http.post('/api/user/client/modify/addr', params)
}
// 收货地址列表
export const useApiOrderGetUserAddrList = () => {
  return Http.get('/api/user/client/addr')
}

// 韩国省级地址
export const useApiOrderGetAddrState = () => {
  return Http.get('/api/user/client/area/state/list')
}
// 韩国城市地址
export const useApiOrderGetAddrCity = (params: { stateId: string }) => {
  return Http.get('/api/user/client/area/city/list', params)
}

// 查询订单列表
export const useApiOrderGetOrderList = (params: PageLimit & { searchKeyword?: string; status?: ORDERSTATUS }) => {
  return Http.get('/api/order/client/order/list', params)
}
// 查询订单详情
export const useApiOrderGetOrderDetail = (params: { orderNo: string }) => {
  return Http.get('/api/order/client/order/detail', params)
}

// 删除订单
export const useApiOrderDelOrder = (params: { orderNo: string }) => {
  return Http.post('/api/order/client/order/del', params)
}

// 确认收货
export const useApiOrderGoodsReceive = (params: { orderNo: string }) => {
  return Http.post('/api/order/client/order/goods/receive', params)
}

// 申请售后
export const useApiOrderAftersaleCreate = (params: {
  orderNo: string
  asType: AFTERSERVICE
  asUserReason: string
  asUserImg: string
  asReasonType: string
  orderItemIds: any
}) => {
  return Http.post('/api/order/client/order/aftersale/create', params)
}

// 查看售后进度
export const useApiOrderLookAftersale = (params: { orderItemId: string }) => {
  return Http.get('/api/order/client/order/aftersale/detail', params)
}

// 查询快递详情
export const useApiOrderLookExpress = (params: { orderNo: string }) => {
  return Http.get('/api/order/client/order/express/detail', params)
}

// 查询第三方快递详情
export const useApiOrderSweetDetail = (params: { orderNo: string }) => {
  return Http.get('/api/order/client/dvy/sweet/detail', params)
}

//通用支付接口
export const useApiPayClientPay = (params: { orderNo: string }) => {
  return Http.post('/api/pay/client/pay', params)
}

//修改订单地址
export const useOrderUpdateAddr = (params: { orderNo: string; addrId: string }) => {
  return Http.post('/api/order/client/order/update/addr', params)
}

//查询待评价列表
export const useOrderUncommentList = (params: PageLimit) => {
  return Http.get('/api/order/client/order/uncomment/list', params)
}

//用户订单评价列表
export const useClientCommentList = (params: PageLimit) => {
  return Http.get('/api/behavior/client/comment/user/list', params)
}

//添加sku到购物车
export const useClientCartAdd = (params: { skuId: string; count: number }) => {
  return Http.post('/api/prod/client/cart/add', params)
}

//更新购物车
export const useClientCartUpdate = (params: { skuId: string; cartId: string; count: number }) => {
  return Http.post('/api/prod/client/cart/update', params)
}

//删除购物车
export const useClientCartDel = (params: { cartIds: any }) => {
  return Http.post('/api/prod/client/cart/del', params)
}

//选中一个购物车
export const useClientCartSelectedOne = (params: { cartIds: any; isSelected: number }) => {
  return Http.post('/api/prod/client/cart/selected/one', params)
}

//全选购物车
export const useClientCartSelectedAll = (params: { isSelected: number }) => {
  return Http.post('/api/prod/client/cart/selected/all', params)
}

//计算选中的商品价格
export const useClientCartCalculate = () => {
  return Http.post('/api/prod/client/cart/calculate')
}

//分页获取购物车内的Sku
export const useClientCartPage = (params: PageLimit) => {
  return Http.get('/api/prod/client/cart/page', params)
}

//获取购物车数量
export const useClientCartCount = () => {
  return Http.get('/api/prod/client/cart/count')
}

//支付结果页查询某个订单的支付状态
export const useClientPayStatus = (orderno: string) => {
  return Http.get(`/api/order/client/order/${orderno}/pay/status`)
}
